import { collection, onSnapshot, query } from 'firebase/firestore';
import React, { useEffect } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import { useText } from '../../contexts/TextContext'
import Campaign from '../../models/Campaign';
import ResultsFilter from '../../models/ResultsFilter';
import Tag from '../../models/Tag';
import { COLLECTIONS, PATHS } from '../../utils/shared/constants';
import { useCompanyId } from '../RequireCompany';
import SlimToggle from '../SlimToggle';

interface Props {
    campaign: Campaign
    filter: ResultsFilter
    updateFilter: (filter: ResultsFilter) => void
}

interface DropdownOption {
    value: string,
    label: string,
}

export default function ResultsToolbar(props: Props) {
    const { campaign } = props;
    const text = useText();
    const firestore = useFirestore();
    const companyId = useCompanyId();
    const [tagsOptions, setTagsOptions] = React.useState<DropdownOption[]>([{
        label: text.info__all_locations,
        value: '',
    }]);
    const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);


    useEffect(() => {
        const tagsQuery = query(collection(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.TAGS}`));
        const tagsSubscription = onSnapshot(tagsQuery, (data) => {
            const newTagsOptions: DropdownOption[] = [{
                label: text.info__all_locations,
                value: '',
            }];

            const newTags = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                } as Tag
            });
            if (newTags) {
                const orderedTags = [...newTags].sort((a, b) => a.name.localeCompare(b.name));
                for (const tag of orderedTags) {
                    newTagsOptions.push({
                        label: tag.name,
                        value: tag.id,
                    });
                }
            }
            setTagsOptions(newTagsOptions);
        });

        const campaignsQuery = query(collection(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CAMPAIGNS}`));
        const campaignsSubscription = onSnapshot(campaignsQuery, (data) => {
            const newCampaigns = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                } as Campaign
            });
            newCampaigns.sort((a, b) => a.name.localeCompare(b.name));
            setCampaigns(newCampaigns);
        });

        return () => {
            tagsSubscription();
            campaignsSubscription();
        }

    }, [companyId, firestore, text.info__all_locations])

    const renderCampaignSwitch = () => {
        if (campaigns.length < 2) {
            return campaign.name
        }

        return (
            <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-campaign" as={SlimToggle}>
                    {campaign.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {campaigns.map((campaignAlternative) => {
                        const isCurrent = campaignAlternative.id === campaign.id;
                        return <Dropdown.Item as={Link} disabled={isCurrent} to={`/${PATHS.CAMPAIGN}/${campaignAlternative.id}`} key={campaignAlternative.id}>
                            {campaignAlternative.name}
                        </Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    const currentTag = tagsOptions.find(t => t.value === props.filter.tag) || tagsOptions[0];
    return (
        <div className='bg-light border-top border-bottom px-4 d-flex align-items-center py-2'>
            <div className='bold center'>{text.results} <span className='ms-5'>{renderCampaignSwitch()}</span></div>
            <span className="mx-3" />


            <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="dropdown-location" as={SlimToggle}>
                    {currentTag.label}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Header>{text.info__select_location}</Dropdown.Header>
                    {tagsOptions.map((option) => {
                        return <Dropdown.Item key={option.value}
                            active={option.value === currentTag.value}
                            disabled={option.value === currentTag.value}
                            onClick={() => props.updateFilter({ ...props.filter, tag: option.value })}>{option.label}</Dropdown.Item>
                    })}
                </Dropdown.Menu>
            </Dropdown>

            <div className='ms-auto'>
                <Link to={`/`}>
                    <button className='btn btn-ternary'>
                        {text.builder__back_to_campaigns}
                    </button>
                </Link>
            </div>
        </div>
    )
}
