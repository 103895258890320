const text = {
    sign_in__header: 'Log in to your account',
    sign_in__description: 'Welcome back! Please enter your details.',
    sign_in__sign_up_header: 'Setup account',
    sign_in__sign_up_description: 'Please enter your details.',
    sign_in__enter_email: 'Enter your email',
    sign_in__dont_have_account: 'Don\'t have an account?',
    sign_in__already_have_account: 'Already have an account?',
    sign_in__google: 'Sign in with Google',
    sign_in__sign_up_google: 'Sign up with Google',
    sign_in: 'Sign in',
    sign_in__sign_up: 'Sign up',
    sign_in__wrong_password: 'Password does not match, please check your email and password',
    sign_in__weak_password: 'Password should be at least 6 characters',
    sign_in__email_already_in_use: 'Email already in use',

    navbar__brand: 'Feedback360',
    navbar__sign_out: 'Sign out',

    company__new_company: 'New company',
    company__company_name: 'Company name',
    company__enter_new_user_email: 'Enter new user email',
    company__edit_company: 'Edit company',

    campaign__new_campaign: 'New campaign',
    campaign__campaign: 'Campaign',
    campaign__campaigns: 'Campaigns',
    campaign__description: 'Campaingns are what groups responses together. You can create multiple campaigns for each company.',
    campaign__respond_url: 'Respond URL',
    campaign__recorded_responses: 'Recorded responses',
    campaign__question: 'Question',
    cmapaign__open_question_label: 'Open question',
    cmapaign__question_label: 'Question (1 answer)',
    cmapaign__final_screen_label: 'Final Screen',
    campaign__answer: 'Answer',
    campaign__answers: 'Answers',
    campaign__thank_you_note: 'Thank you note',
    campaign__template: 'Template',
    campaign__template_base: 'Base',
    campaign__template_blank: 'Blank',
    campaing__are_you_sure: 'Are you sure you want to delete this campaign?',
    campaing__view_results: 'View results',

    links__links: 'Links',
    links__links_description: 'Links are used to collect feedback from your customers. You may generate general and locations specific links.',
    links__general_link: 'General link',
    links__generate_link: 'Generate link',
    links__are_you_sure: 'Are you sure you want to delete this link?',
    links__location_specific: 'Locations specific links.',
    links__qrCode: 'QR Code Generated',
    links__qrCodeDescription: 'Please scan this QR code with your smartphone camera app and access the campaign.',

    info__last_month: 'Last month',
    info__summary: 'Summary',
    info__results: 'Results',
    info__open_feedback: 'Open feedback',
    info__highlights: 'Highlights',
    info__pain_points: 'Pain points',
    info__no_responses: 'No responses',
    info__last_7_days: 'Last 7 days',
    info__last_30_days: 'Last 30 days',
    info__since_creation: 'Since creation',
    info__select_period: 'Select period',
    info__all_locations: 'All locations',
    info__select_location: 'Select location',

    summary__responses: 'Responses',
    summary__positive: 'Positive',
    summary__negative: 'Negative',
    summary__happy_index: 'Happy index',

    response__no_campaign_found: 'We could not find the campaign you are looking for.',
    response__submitted: 'Thank you for your feedback!',

    breadcrumb__campaign_info: 'Campaign info',
    breadcrumb__campaign_builder: 'Campaign builder',

    builder__add_page: 'Add page',
    builder__remove_page: 'Remove page',
    builder__conditions: 'Conditions',
    builder__overall_score: 'Overall score',
    builder__back_to_campaigns: 'Back to campaigns',
    builder__are_you_sure: 'Are you sure you want to delete this page?',

    template__base_overall_score: 'How was your shopping experience?',
    template__base_open_question: 'Would you like to add anything?',
    template__base_negative_question: 'Sorry to hear that! What could we do better?',
    template__base_negative_answer_labels: 'Price labels were missing',
    template__base_negative_answer_staff: 'Staff was unkind and lacked empathy',
    template__base_negative_answer_slow: 'Checkout was slow',
    template__base_negative_answer_price: 'Prices were on the higher side',
    template__base_positive_question: 'Great! What did you like the most?',
    template__base_positive_answer_labels: 'Everythig was labeled well',
    template__base_positive_answer_staff: 'Staff was warm and helpful',
    template__base_positive_answer_fast: 'Checkout was fast and smooth',
    template__base_positive_answer_price: 'Prices were competitive',

    reset__text: 'Response is about to reset. Please let us know if you would like to continue.',
    reset__keep_changes: 'Keep changes',

    locations__locations: 'Locations',
    locations__new_location: 'New Location',
    locations__locations_description: 'Locations lets you reuse the same campaign. After results are coolected they can be viewed for specific location as well as globally.',
    locations__no_locations: 'No locations created yet',
    locations__are_you_sure: 'Are you sure you want to delete this location?',

    companies__companies: 'Companies',
    companies__users: 'Users',
    companies__company_required: 'No company detected. Please contact us and we will gladly help you get started.',
    companies__are_you_sure: 'Are you sure you want to delete this company? All campaingns and collected data will be lost.',

    name: 'Name',
    save: 'Save',
    cancel: 'Cancel',
    info: 'Info',
    edit: 'Edit',
    remove: 'Remove',
    comingSoon: 'Coming soon',
    email: 'Email',
    password: 'Password',
    next: 'Next',
    showMore: 'Show more',
    showLess: 'Show less',
    publish: 'Publish',
    results: 'Results',
    back: 'Back',
    confirm: 'Confirm',
    confirmation: 'Confirmation',
    download: 'Download',
    skip: 'Skip',
    copy: 'Copy',
    score: 'Score',
}

export default text;