import React from 'react'
import Campaign from '../../models/Campaign';
import CampaignStep from '../../models/CampaignStep';
import FinalScreen from '../steps/FinalScreen';
import OpenText from '../steps/OpenText';
import OverallScore from '../steps/OverallScore';
import Question from '../steps/Question';

interface Props {
    campaign: Campaign;
    updateCampaign: (campaign: Campaign) => void;
    currentStep: number;
}

export default function BuilderEditor(props: Props) {
    const step = props.campaign.steps[props.currentStep];

    const onStepChange = (newStep: CampaignStep) => {
        const newSteps = props.campaign.steps.slice(0, props.currentStep).concat(newStep).concat(props.campaign.steps.slice(props.currentStep + 1));
        const newCampaign = { ...props.campaign, steps: newSteps };
        props.updateCampaign(newCampaign);
    }

    const renderStep = () => {
        switch (step.type) {
            case 'overallScore':
                return <OverallScore step={step} disabled={true} onChange={onStepChange} editable={true} />
            case 'question':
                return <Question step={step} disabled={true} onChange={onStepChange} editable={true} />;
            case 'openText':
                return <OpenText step={step} disabled={true} onChange={onStepChange} editable={true} />;
            case 'finalScreen':
                return <FinalScreen step={step} onChange={onStepChange} editable={true} />;
            default:
                return <p>TODO</p>
        }
    }

    return (
        <div className='center flex-column flex-1'>
            {renderStep()}
        </div>
    )
}
