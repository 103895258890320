import { doc, setDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useFirestore, useFirestoreDocData } from 'reactfire';
import BuilderEditor from '../components/builder/BuilderEditor';
import BuilderLeftPanel from '../components/builder/BuilderLeftPanel';
import BuilderTools from '../components/builder/BuilderTools';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import { useCompanyId } from '../components/RequireCompany';
import Campaign from '../models/Campaign';
import { COLLECTIONS } from '../utils/shared/constants';

export default function CampaignBuilderPage() {
    const campaignId = useParams().campaignId;
    const firestore = useFirestore();
    const companyId = useCompanyId();
    const campaignDocRef = doc(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CAMPAIGNS}/${campaignId}`);
    const { data } = useFirestoreDocData(campaignDocRef, { idField: 'id' });
    const [campaign, setCampaign] = useState<Campaign | null>(null);
    const [currentStep, setCurrentStep] = React.useState(0);

    useEffect(() => {
        if (!campaign && data) {
            setCampaign(data as Campaign);
        }
    }, [data, campaign])

    if (!campaign) {
        return <Layout><Loading /></Layout>
    }

    const updateCampaign = async (newCampaign: Campaign) => {
        setCampaign(newCampaign);
        await setDoc(campaignDocRef, newCampaign);
    }

    return (
        <div className='flex-1 d-flex flex-column h-100'>
            <BuilderTools campaign={campaign} updateCampaign={updateCampaign} currentStepIx={currentStep} updateCurrentStepIx={setCurrentStep} />
            <div className='d-flex flex-1 overflow-auto'>
                <BuilderLeftPanel campaign={campaign} currentStep={currentStep} updateCurrentStep={setCurrentStep} />
                <BuilderEditor campaign={campaign} currentStep={currentStep} updateCampaign={updateCampaign} />
            </div>

        </div>
    )
}
