import { EMOJI_COLORS } from '../../utils/shared/constants';
import styled, { Keyframes, keyframes } from 'styled-components'

interface Props {
    value: number,
}

const PROGRESS_SIZE = 100;
const INNER_SIZE = 80;

const MaskDiv = styled.div<{ loadingProgress: Keyframes }>`
    animation: ${props => props.loadingProgress} 0.5s linear;
`

export default function HapinessIndex(props: Props) {
    const remaining = PROGRESS_SIZE - PROGRESS_SIZE * props.value;
    const finalRect = `rect(${remaining}px, ${PROGRESS_SIZE}px, ${PROGRESS_SIZE}px, 0px)`

    const loadingProgress = keyframes`
        0% {
            clip: rect(${PROGRESS_SIZE}px, ${PROGRESS_SIZE}px, ${PROGRESS_SIZE}px, 0px)
        }
        100% {
            clip: ${finalRect}
        }
    `

    const baseSizeCSS = {
        width: `${PROGRESS_SIZE}px`,
        height: `${PROGRESS_SIZE}px`,
    }
    const insetSizeCSS = {
        width: `${INNER_SIZE}px`,
        height: `${INNER_SIZE}px`,
    }

    return (
        <div className='center flex-column mx-2 happy-ix' style={baseSizeCSS}>
            <div className='inner center flex-column' style={insetSizeCSS}>

            </div>


            <MaskDiv className='mask'
                loadingProgress={loadingProgress}
                style={{
                    ...baseSizeCSS,
                    clip: finalRect,
                    background: `linear-gradient(${EMOJI_COLORS[0]}, ${EMOJI_COLORS[3]})`,
                }} />
            <div className='backdrop' style={baseSizeCSS} />
        </div>
    )
}
