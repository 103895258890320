import React from 'react';
import './styles/App.scss';
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, useFirebaseApp } from 'reactfire';
import firebaseConfig from './utils/firebaseConfig';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import RequireAuth from './components/RequireAuth';
import RequireCompany from './components/RequireCompany';
import { TextProvider } from './contexts/TextContext';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import DashboardPage from './pages/DashboardPage';
import { PATHS } from './utils/shared/constants';
import CampaignInfoPage from './pages/CampaignInfoPage';
import ResponsePage from './pages/ResponsePage';
import CampaignBuilderPage from './pages/CampaignBuilderPage';
import ResetResponseProvider from './contexts/ResetResponseContext';
import LocationsPage from './pages/LocationsPage';
import CampaignLinksPage from './pages/CampaignLinksPage';
import CompaniesPage from './pages/CompaniesPage';
import ResetPasswordPage from './pages/ResetPasswordPage';


interface AuthAppProps {
  children: any
  tolerateNoCompany?: boolean
}
const AuthApp = (props: AuthAppProps) => {
  return <RequireAuth>
    <RequireCompany tolerateNoCompany={props.tolerateNoCompany}>
      {props.children}
    </RequireCompany>
  </RequireAuth>
}

const FirebaseApp = () => {
  const useEmulators = process.env.REACT_APP_USE_EMULATORS || false;
  const firebaseApp = useFirebaseApp();
  const firestoreInstance = getFirestore(firebaseApp);
  if (useEmulators) {
    connectFirestoreEmulator(firestoreInstance, "localhost", 8080);
  }

  const authInstance = getAuth(firebaseApp);
  if (useEmulators) {
    connectAuthEmulator(authInstance, "http://localhost:9099");
  }


  return <FirestoreProvider sdk={firestoreInstance}>
    <AuthProvider sdk={authInstance}>
      <TextProvider>
        <BrowserRouter>
          <Routes>
            <Route path={`/${PATHS.PWRD_RESET}`} element={<ResetPasswordPage />} />

            <Route path="/" element={<AuthApp><DashboardPage /></AuthApp>} />
            <Route path={`/${PATHS.CAMPAIGN}/:campaignId`} element={<AuthApp><CampaignInfoPage /></AuthApp>} />
            <Route path={`/${PATHS.BUILDER}/:campaignId`} element={<AuthApp><CampaignBuilderPage /></AuthApp>} />
            <Route path={`/${PATHS.LINKS}/:campaignId`} element={<AuthApp><CampaignLinksPage /></AuthApp>} />
            <Route path={`/${PATHS.LOCATIONS}`} element={<AuthApp><LocationsPage /></AuthApp>} />

            <Route path={`/${PATHS.COMPANIES}`} element={<AuthApp tolerateNoCompany={true}><CompaniesPage /></AuthApp>} />


            <Route path={`/${PATHS.RESPOND}/:linkId`} element={<ResetResponseProvider><ResponsePage /></ResetResponseProvider>} />
          </Routes>
        </BrowserRouter>
      </TextProvider>
    </AuthProvider>
  </FirestoreProvider>
}

export default function App() {

  return <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <FirebaseApp />
  </FirebaseAppProvider>
}
