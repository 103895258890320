import React, { useRef } from 'react'

interface Props {
    value: string;
    onChange: (value: string) => void;
}

const EXTRA_CHARS = 3;

export default function EditableText(props: Props) {
    const [editMode, setEditMode] = React.useState(false);
    const [value, setValue] = React.useState(props.value);
    const inputInput = useRef(null);


    const toggleEditMode = () => {
        if (editMode) {
            props.onChange(value);
        }
        setEditMode(!editMode);
    }
    function handleSubmit(e: React.FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        toggleEditMode();
        setTimeout(() => (inputInput.current as any).focus(), 100)
    }

    const extraWidth = editMode ? EXTRA_CHARS : 0;
    return (
        <form className="center" onSubmit={e => handleSubmit(e)} style={{ width: 'unset' }}>
            <input ref={inputInput} type="text"
                className={`form-control flex-unset ${editMode ? ' ' : 'inline'}`}
                disabled={!editMode}
                value={value} onChange={(e) => setValue(e.target.value)}
                onKeyDown={(e: any) => { e.target.style.width = `${e.target.value.length + extraWidth}ch`; }}
                style={{ width: `${value.length + extraWidth}ch` }}
            />
            <button className={`btn ${editMode ? 'btn-success' : ''}`} >
                <i className={`bi ${editMode ? 'bi-check-lg' : 'bi-pencil-fill'}`} />
            </button>
        </form>
    )
}


