import React, { useMemo } from 'react'
import { useText } from '../contexts/TextContext'
import ResultsFilter, { PeriodKey } from '../models/ResultsFilter'

interface PeriodOption {
    value: PeriodKey,
    label: string,
}

interface Props {
    filter: ResultsFilter
    updateFilter: (filter: ResultsFilter) => void
}

export default function PeriodPicker(props: Props) {
    const text = useText();

    const periodOptions: PeriodOption[] = useMemo(() => {
        return [
            {
                value: 'max',
                label: text.info__since_creation,
            },
            {
                value: 'last_30_days',
                label: text.info__last_30_days,
            },
            {
                value: 'last_7_days',
                label: text.info__last_7_days,
            },
        ];
    }, [text])

    const currentPeriod = periodOptions.find(p => p.value === props.filter.period) || periodOptions[0];

    return (
        <div className="btn-group" role="group">
            {periodOptions.map((option) => {
                const active = option.value === currentPeriod.value;
                return <button className={`btn ${active ? 'btn-secondary' : 'btn-outline-secondary'}`} key={option.value}
                    disabled={active}
                    onClick={() => props.updateFilter({ ...props.filter, period: option.value })}>{option.label}</button>
            })}
        </div>
    )
}
