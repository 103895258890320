import React from 'react'

interface Props {
    children: React.ReactNode;
    className?: string;
}

export default function Card(props: Props) {
    return (
        <div className={`border rounded p-1 m-1 h-100 shadow ${props.className}`} >
            <div className='w-100'>


                {props.children}
            </div>
        </div>
    )
}
