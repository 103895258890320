import React, { useContext, useEffect, useState } from "react"
import { Modal } from "react-bootstrap";
import { useText } from "./TextContext";


const TIMER_KEY = 'feedback_reset_counter';
// in seconds
const CHECK_INTERVAL = 1;
const RESET_INTERVAL = 15;
const WARNING_INTERVAL = 5;
const SHORT_INTERVAL = 3;

interface ResetResponseContext {
    activate: (callback: Function) => void;
    setShort: (short: boolean) => void;
};
const resetResponseContext = React.createContext<ResetResponseContext>({ activate: () => { }, setShort: () => { } });

export function useResetResponse() {
    return useContext(resetResponseContext)
}


export default function ResetResponseProvider({ children }: any) {
    const [showWarning, setShowWaring] = useState(false);
    const text = useText();
    const [callbacks, setCallbacks] = useState<Function[]>([]);
    const [short, setShort] = useState(false);

    const supressReset = window.location.search.includes('noReset');


    const resetTime = () => {
        const newResetTime = new Date();
        const finalInterval = short ? SHORT_INTERVAL : RESET_INTERVAL;
        newResetTime.setSeconds(newResetTime.getSeconds() + finalInterval);
        window.localStorage.setItem(TIMER_KEY, newResetTime.toISOString());


        setShowWaring(false);
    }

    const check = () => {
        const refreshTimeString = window.localStorage.getItem(TIMER_KEY);

        if (refreshTimeString) {
            const date = new Date(refreshTimeString)
            const now = new Date();
            const diff = date.getTime() - now.getTime();
            const diffSeconds = Math.round(diff / 1000);

            console.log('Reset countdown', diffSeconds);
            if (diff < 0) {
                callbacks[0]();

            } else if (diffSeconds <= WARNING_INTERVAL && !short) {
                setShowWaring(true);
            }
        } else {
            resetTime();
        }
    }

    useEffect(() => {
        if (!callbacks.length || supressReset) {
            return;
        }

        resetTime();
        const interval = setInterval(check, CHECK_INTERVAL * 1000)

        const clickHandler = () => {
            resetTime();
        }
        document.addEventListener("click", clickHandler)
        document.addEventListener("keypress", clickHandler)

        return () => {
            clearInterval(interval);
            document.removeEventListener("click", clickHandler)
            document.removeEventListener("keypress", clickHandler)
        }
        // eslint-disable-next-line
    }, [callbacks, short])

    return (
        <resetResponseContext.Provider value={{
            activate: callback => setCallbacks([callback]),
            setShort,
        }} >
            {children}
            <Modal show={showWarning} className="modal fade" size='lg'>
                <div className="modal-content center p-3">
                    <p>{text.reset__text}</p>
                    <button className="btn btn-primary">{text.reset__keep_changes}</button>
                </div>
            </Modal>
        </resetResponseContext.Provider>
    )
}
