import React from 'react'
import { useText } from '../../contexts/TextContext';
import Campaign from '../../models/Campaign';
import CampaignStep from '../../models/CampaignStep';
import EditableText from '../EditableText';
import { v4 as uuid } from 'uuid';
import { Dropdown } from 'react-bootstrap';
import Emoji from '../Emoji';
import useQueryParam from '../../hooks/useQueryParams';
import { Link } from 'react-router-dom';
import useConfirmModal from '../../hooks/useConfirmModal';
import { PATHS } from '../../utils/shared/constants';
import SlimToggle from '../SlimToggle';

interface Props {
    campaign: Campaign;
    updateCampaign: (campaign: Campaign) => void;
    currentStepIx: number;
    updateCurrentStepIx: (step: number) => void;
}


export default function BuilderTools(props: Props) {
    const { campaign, updateCampaign, currentStepIx, updateCurrentStepIx } = props;
    const currentStep = campaign.steps[currentStepIx];
    const changable = currentStepIx > 0;
    const text = useText();
    const { newParam } = useQueryParam();
    const [ConfirmModal, confirm] = useConfirmModal(text.builder__are_you_sure);

    const TYPE_SELECT = [
        { value: 'question', label: text.cmapaign__question_label },
        { value: 'openText', label: text.cmapaign__open_question_label },
        { value: 'finalScreen', label: text.cmapaign__final_screen_label },
    ]


    const addStep = () => {
        const newStep: CampaignStep = { type: 'question', text: '', options: [] };
        const newSteps = campaign.steps.slice(0, currentStepIx + 1).concat(newStep).concat(campaign.steps.slice(props.currentStepIx + 1));
        const newCampaign = { ...campaign, steps: newSteps };
        updateCampaign(newCampaign);
        updateCurrentStepIx(currentStepIx + 1);
    }

    const removeStep = () => {
        confirm(() => {
            const newSteps = campaign.steps.slice(0, currentStepIx).concat(campaign.steps.slice(currentStepIx + 1));
            const newCampaign = { ...campaign, steps: newSteps };
            updateCampaign(newCampaign);
            updateCurrentStepIx(currentStepIx - 1);
        });
    }

    const updateCurrentStep = (newStep: CampaignStep) => {
        const newSteps = campaign.steps.slice(0, currentStepIx).concat(newStep).concat(campaign.steps.slice(currentStepIx + 1));
        const newCampaign = { ...campaign, steps: newSteps };
        updateCampaign(newCampaign);
    }

    const changeAnswerCount = (count: number) => {
        let newOptions = currentStep.options;
        if (count > currentStep.options.length) {
            for (let i = currentStep.options.length; i < count; i++) {
                newOptions.push({
                    id: uuid(),
                    text: text.campaign__answer + ' ' + (i + 1),
                })
            }
        } else {
            newOptions = newOptions.slice(0, count);
        }
        const newStep = { ...currentStep, options: newOptions };
        updateCurrentStep(newStep);
    }

    const updateCondition = (ix: number, add: boolean) => {
        let overallScores = [...(currentStep.conditions?.overallScore || [])];
        if (add) {
            overallScores.push(ix);
        } else {
            overallScores = overallScores.filter(i => i !== ix);
        }

        const newConditions = { ...(currentStep.conditions || {}), overallScore: overallScores };
        const newStep = { ...currentStep, conditions: newConditions };
        updateCurrentStep(newStep);
    }

    const renderStepMenu = () => {
        if (!changable) {
            return null;
        }

        const showAnswerCount = currentStep.type === 'question';
        const conditionOverallScore = currentStep.conditions?.overallScore || [];

        return <>
            <span className='mx-5' />

            <select className="form-select" style={{ width: 'unset' }}
                value={currentStep.type}
                onChange={e => updateCurrentStep({ ...currentStep, type: e.target.value as any })}>
                {TYPE_SELECT.map((option) => {
                    return <option key={option.value} value={option.value}>{option.label}</option>
                })}
            </select>

            {showAnswerCount && <div className='center'>
                <label className="ms-4">{text.campaign__answers}:</label>
                <input className="form-control ms-1" type="number" min={1}
                    style={{ width: '8ch' }}
                    value={currentStep.options.length || 0}
                    onChange={e => changeAnswerCount(e.target.valueAsNumber)} />
            </div>}

            <div className="ms-4" >
                <Dropdown>
                    <Dropdown.Toggle variant='form-control' as={SlimToggle}>
                        {text.builder__conditions}{conditionOverallScore.length > 0 && <span className="badge bg-primary ms-1">{conditionOverallScore.length}</span>}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className='p-2'>
                            <p className='bold m-0'>{text.builder__overall_score}</p>
                            {[0, 1, 2, 3].map((ix) => {
                                const checked = conditionOverallScore.includes(ix) || false;
                                return <div className="d-flex align-items-center" key={ix}>
                                    <input className="form-check-input me-2" type="checkbox"
                                        checked={checked}
                                        onChange={e => updateCondition(ix, e.target.checked)} />
                                    <label className="form-check-label" >
                                        <Emoji ix={ix} size="small" />
                                    </label>
                                </div>
                            })}
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>


        </>
    }

    return (
        <div>
            <div className='py-2 d-flex align-items-center'>
                <div className='ps-4 py-1 d-flex builder-tools-above-list'>

                    <EditableText value={campaign.name} onChange={(value) => updateCampaign({ ...campaign, name: value })} />

                    <div className='d-flex ms-auto'>

                        <button className='btn btn-primary btn-builder'
                            style={{ fontSize: 'x-large' }}
                            onClick={() => addStep()}>
                            +
                        </button>
                        <button className='btn btn-outline-secondary btn-builder ms-1' disabled={!changable}
                            style={{ fontSize: 'larger' }}
                            onClick={() => removeStep()}>
                            <i className="bi bi-trash3" />
                        </button>
                    </div>
                </div>

                {renderStepMenu()}

                <div className='ms-auto me-4'>
                    {newParam
                        ? <Link to={`/${PATHS.LINKS}/${campaign.id}`}><button className='btn btn-primary'>{text.publish}</button></Link>
                        : <Link to={`/`}><button className='btn btn-outline-primary'>
                            {text.builder__back_to_campaigns}
                        </button></Link>}
                </div>
            </div>

            <ConfirmModal />
        </div>
    )
}
