import React from 'react'
import SignInIcon from '../components/SignInIcon'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { useText } from '../contexts/TextContext';
import { Link } from 'react-router-dom';
import { AdvancedButton } from '../components/AdvancedButton';
import { useAuth } from 'reactfire';
import { sendPasswordResetEmail } from 'firebase/auth';

interface Props { }

interface Inputs {
    email: string,
}

function ResetPasswordPage(props: Props) {
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
    const [processing, setProcessing] = React.useState(false);
    const [finished, setFinished] = React.useState(false);
    const text = useText();
    const auth = useAuth();

    const onSubmit = async (data: Inputs): Promise<void> => {
        setProcessing(true)

        await sendPasswordResetEmail(auth, data.email)

        setProcessing(false)
        setFinished(true)
    }

    if (finished) {
        return <div className="center h-100">
            <div className='center flex-column' >
                <SignInIcon />
                <h2>Reset Password</h2>
                <p>We have sent you an email with instructions to reset your password</p>
                <Link to="/" className='mt-3'><Button variant='outline-secondary'>Back</Button></Link>
            </div>
        </div>
    }

    return <div className="center h-100">
        <div className='center flex-column' >
            <SignInIcon />
            <h2>Reset Password</h2>
            <p>Enter your email address and we will send you a link to reset your password</p>


            <Form className='d-flex flex-column w-100' onSubmit={handleSubmit(onSubmit)}>
                <label>{text.email}</label>
                <Form.Control
                    className={`mb-3 ${errors.email ? 'is-invalid' : ''}`}
                    type="email"  {...register("email", { required: true })}
                />

                <div className='mt-3 d-flex gap'>
                    <Link to="/" className='flex-1'><Button className='w-100' variant='outline-secondary'>Back</Button></Link>
                    <AdvancedButton processing={processing} className='flex-1' type='submit'>Send Reset Link</AdvancedButton>

                </div>
            </Form>
        </div>

    </div>
}

export default ResetPasswordPage
