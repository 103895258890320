import React from 'react'
import Campaign from '../../models/Campaign';
import StepIcon from './StepIcon';

interface Props {
    campaign: Campaign;
    currentStep: number;
    updateCurrentStep: (step: number) => void;
}

export default function BuilderLeftPanel(props: Props) {
    return (
        <div className='builder-list p-4 overflow-auto'>
            {props.campaign.steps.map((step, index) => {
                return (
                    <div key={index} className={`builder-list-item p-1 pointer rounded ${index === props.currentStep ? 'selected' : ''}`}
                        onClick={() => props.updateCurrentStep(index)}>
                        <StepIcon step={step} />
                    </div>
                )
            })}
        </div>
    )
}
