import { PARAMS } from "../utils/shared/constants";

export const parseQueryParam = () => {
    const search = window.location.search;
    const urlParams = new URLSearchParams(search);
    const newParam = urlParams.get(PARAMS.NEW);
    return {
        newParam: !!newParam,
        invite: urlParams.get(PARAMS.INVITE),
        debug: urlParams.get(PARAMS.DEBUG),
        signUp: !!urlParams.get(PARAMS.SIGN_UP),
    }
}

const useQueryParam = () => {
    return parseQueryParam();
}


export default useQueryParam;
