import React, { useMemo } from 'react'
import { useText } from '../../contexts/TextContext'
import CampaignStep from '../../models/CampaignStep';
import Response from '../../models/Response';
import { formatPercentage } from '../../utils/math';
import Emoji from '../Emoji';
import Card from './Card';

interface Props {
    responses: Response[],
    step: CampaignStep
}

export default function Summary(props: Props) {
    const { responses } = props;
    const text = useText();


    const aggregated = useMemo(() => {
        const scores: Record<number, number> = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
        }

        for (const response of responses) {
            scores[Number(response.steps[0].value)]++;
        }


        return {
            scores,
            total: responses.length,
        }
    }, [responses]);

    const possitiveCount = aggregated.scores[0] + aggregated.scores[1];
    const negativeCount = aggregated.scores[2] + aggregated.scores[3];
    return (
        <Card>
            <div className='w-100'>

                <div className='flex-column mx-4'>
                    <p className='bold mb-1'>{props.step.text}</p>
                    <p>
                        <span className=''>{aggregated.total}</span> {text.summary__responses}, <span className=''>{possitiveCount}</span> {text.summary__positive} <span className=''>{negativeCount}</span> {text.summary__negative}
                    </p>
                </div>
                <div className={`center justify-content-between flex-wrap`}>
                    {[0, 1, 2, 3].map((ix) => {
                        const count = aggregated.scores[ix];
                        return <div key={ix} className="center m-2" >
                            <Emoji size="small" ix={ix} />
                            <h4 className='m-0 ms-2'>{formatPercentage(count / aggregated.total)}</h4>
                        </div>
                    })}
                </div>
            </div>
        </Card>
    )
}
