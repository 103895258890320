import { collection, deleteDoc, doc, onSnapshot, Query, query, where } from 'firebase/firestore';
import React, { useEffect } from 'react'
import { Dropdown } from 'react-bootstrap';
import { useFirestore } from 'reactfire';
import Layout from '../components/Layout'
import NewLocationModal from '../components/modals/NewLocationModal';
import { useCompanyId } from '../components/RequireCompany';
import ThreeDotsToggle from '../components/ThreeDotsToggle';
import { useText } from '../contexts/TextContext'
import useConfirmModal from '../hooks/useConfirmModal';
import Tag from '../models/Tag';
import { COLLECTIONS } from '../utils/shared/constants';

export default function LocationsPage() {
    const text = useText();
    const [showNewLocation, setShowNewLocation] = React.useState(false);

    const [ConfirmModal, confirm] = useConfirmModal(text.locations__are_you_sure);
    const firestore = useFirestore();
    const companyId = useCompanyId();
    const [locations, setLocations] = React.useState<Tag[]>([]);


    useEffect(() => {
        const locationsQuery = query(collection(firestore,
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.TAGS}`), where("type", "==", 'location')) as Query<Tag>;
        return onSnapshot(locationsQuery, (data) => {
            const locations = data.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                } as Tag
            });
            locations.sort((a, b) => a.name.localeCompare(b.name));
            setLocations(locations);
        }, (error) => { console.log('error loading locations', error) });
    }, [companyId, firestore])

    const handleDelete = (id: string) => {
        confirm(async () => {
            await deleteDoc(doc(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.TAGS}/${id}`));
        })
    }

    return (
        <Layout>
            <div className='d-flex align-items-start justify-content-between  mb-4'>
                <div>
                    <h1>{text.locations__locations}</h1>
                    <p>{text.locations__locations_description}</p>
                </div>
                <button className='btn btn-primary no-wrap ms-2 mt-3' onClick={() => setShowNewLocation(true)}>+ {text.locations__new_location}</button>
            </div>

            <NewLocationModal show={showNewLocation} onHide={() => setShowNewLocation(false)} />
            <ConfirmModal />

            {locations.length
                ? <div className=''>
                    <div className='bg-light p-3'>
                        {text.name}
                    </div>
                    {locations.map(location =>
                        <div key={location.id} className='border-bottom px-4 py-3 d-flex align-items-center justify-content-between'>
                            <div className='flex-1 pe-5 center justify-content-start'>
                                <div className='center me-2 bg-primary-light p-2 rounded-circle center' style={{ width: '45px' }}>
                                    <i className="bi bi-geo-alt text-primary h4 m-0" />
                                </div>
                                <div>{location.name}</div>
                            </div>
                            <Dropdown>
                                <Dropdown.Toggle as={ThreeDotsToggle} />
                                <Dropdown.Menu align="end">
                                    <Dropdown.Item className="text-light bg-danger" onClick={() => handleDelete(location.id)}>{text.remove}</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>)}
                </div>
                : <div className='center mt-5'>
                    <p className='text-info'>{'<< ' + text.locations__no_locations + ' >>'}</p>
                </div>
            }
        </Layout>
    )
}
