import React, { useRef } from 'react'
import { useText } from '../contexts/TextContext';
import QRCodeModal from './modals/QRCodeModal';

interface Props {
    value: string;
    className?: string;
}

export default function ResponseLinkText(props: Props) {
    const inputRef = useRef(null);
    const [showQR, setShowQR] = React.useState(false);
    const text = useText();

    const handleCopy = () => {
        const ref = inputRef.current;
        if (ref) {
            (inputRef.current! as any).select();
            document.execCommand('copy');
        }
    }

    return (
        <div className={`d-flex rounded border ${props.className || ''}`}>

            <input ref={inputRef} value={props.value} readOnly={true}
                className="flex-1 p-2" style={{ border: 'none', outline: 'none' }} />
            <button className='py-1 px-2 btn' onClick={() => setShowQR(true)}>
                <i className="bi bi-qr-code h4 m-0" />
            </button>
            <span className='vr mx-2' />
            <button className='py-1 px-2 btn center me-2' onClick={handleCopy}>
                <i className="bi bi-files h4 m-0 me-2" />{text.copy}
            </button>

            <QRCodeModal show={showQR} value={props.value} onHide={() => setShowQR(false)} />
        </div>
    )
}
