import { collection, deleteDoc, doc, query, setDoc, where } from 'firebase/firestore';
import { useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router'
import { useFirestore, useFirestoreCollectionData } from 'reactfire';
import ResponseLinkText from '../components/ResponseLinkText';
import Layout from '../components/Layout'
import Loading from '../components/Loading';
import Navigation from '../components/Navigation';
import { useCompanyId } from '../components/RequireCompany';
import ThreeDotsToggle from '../components/ThreeDotsToggle';
import { useText } from '../contexts/TextContext';
import useConfirmModal from '../hooks/useConfirmModal';
import ResponseLink from '../models/ResponseLink';
import Tag from '../models/Tag';
import { COLLECTIONS } from '../utils/shared/constants';
import { shortId } from '../utils/math';
import { getCampaignUrl } from '../utils/responses';

export default function CampaignLinksPage() {
    const campaignId = useParams().campaignId;
    const firestore = useFirestore();
    const companyId = useCompanyId();
    const text = useText();
    const [generating, setGenerating] = useState<Set<string>>(new Set());
    const [ConfirmModal, confirm] = useConfirmModal(text.links__are_you_sure);

    const tagsQuery = query(collection(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.TAGS}`));
    const { data: tagsData } = useFirestoreCollectionData(tagsQuery, { idField: 'id', });
    const tags = useMemo(() => tagsData ? [...tagsData].sort(((a, b) => a.name.localeCompare(b.name))) : [], [tagsData]) as Tag[];

    const linksQuery = query(collection(firestore, COLLECTIONS.LINKS), where('campaign', '==', campaignId));
    const { status, data: linksData } = useFirestoreCollectionData(linksQuery, { idField: 'id', });
    const links = linksData as ResponseLink[];


    if (status === 'loading') {
        return <Layout><Loading /></Layout>
    }

    const generalLink = links.find((link) => link.tags.length === 0);

    const generateLink = async (tagId: string) => {
        setGenerating(new Set(generating.add(tagId)));

        const newLink: ResponseLink = {
            ...generalLink!,
            tags: [tagId],
            id: shortId(),
        }

        await setDoc(doc(firestore, COLLECTIONS.LINKS, newLink.id), newLink);
    }


    const removeLink = async (linkId: string) => {
        confirm(async () => {
            await deleteDoc(doc(firestore, COLLECTIONS.LINKS, linkId));
        });
    }

    return (
        <Layout>
            <Navigation />
            <h1>{text.links__links}</h1>
            <p className='mb-4'>{text.links__links_description}</p>
            <label className='bold'>{text.links__general_link}</label>
            <ResponseLinkText value={getCampaignUrl(generalLink!)} />


            <ConfirmModal />
            {!!tags.length && (
                <div className='mt-5'>
                    <h2>{text.locations__locations}</h2>
                    <p className='mb-4'>{text.links__location_specific}</p>

                    {tags.map((tag) => {
                        const tagLink = links.find((link) => link.tags.includes(tag.id));
                        const isBeignGenerated = generating.has(tag.id);
                        return (
                            <div key={tag.id} className="mb-4">
                                <div className='bold'>{tag.name}</div>
                                {tagLink
                                    ? <div className='center '>
                                        <ResponseLinkText className="ms-2 flex-1" value={getCampaignUrl(tagLink)} />
                                        <Dropdown className='ms-2'>
                                            <Dropdown.Toggle as={ThreeDotsToggle} />
                                            <Dropdown.Menu align="end">
                                                <Dropdown.Item className="text-light bg-danger" onClick={() => removeLink(tagLink.id)}>{text.remove}</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    : <button className='btn btn-outline-secondary' disabled={isBeignGenerated} onClick={() => generateLink(tag.id)}>
                                        {isBeignGenerated && <span className="spinner-grow spinner-grow-sm me-2" role="status" />}
                                        <i className="bi bi-link-45deg me-2" />
                                        {text.links__generate_link}
                                    </button>
                                }
                            </div>
                        )
                    })}
                </div>
            )}

        </Layout>
    )
}
