import { collection, doc, query, where } from 'firebase/firestore';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router'
import { useFirestore, useFirestoreCollectionData, useFirestoreDocData } from 'reactfire';
import Layout from '../components/Layout'
import Loading from '../components/Loading';
import PeriodPicker from '../components/PeriodPicker';
import { useCompanyId } from '../components/RequireCompany';
import Card from '../components/vizializations/Card';
import HappyIndexWrapper from '../components/vizializations/HappyIndexWrapper';
import OpenQuestionPreview from '../components/vizializations/OpenQuestionPreview';
import QuestionVisualizations from '../components/vizializations/QuestionVisualizations';
import ResultsToolbar from '../components/vizializations/ResultsToolbar';
import Summary from '../components/vizializations/Summary';
import Timeline from '../components/vizializations/Timeline';
import { useText } from '../contexts/TextContext';
import Campaign from '../models/Campaign';
import Response from '../models/Response';
import ResultsFilter from '../models/ResultsFilter';
import { COLLECTIONS } from '../utils/shared/constants';



export default function CampaignInfoPage() {
    const campaignId = useParams().campaignId;
    const firestore = useFirestore();
    const companyId = useCompanyId();
    const [filter, setFilter] = useState<ResultsFilter>({ period: 'last_30_days', tag: '' });
    const { data } = useFirestoreDocData(doc(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CAMPAIGNS}/${campaignId}`), { idField: 'id' });
    const campaign = data as Campaign;
    const responseBreakDate = useMemo(() => {
        let date = new Date();
        switch (filter.period) {
            case 'last_30_days':
                date.setDate(date.getDate() - 30);
                break;
            case 'last_7_days':
                date.setDate(date.getDate() - 7);
                break;
            case 'max':
                date = new Date(campaign.createdAt);
                date.setHours(0, 0);
                break;
        }
        return date;
    }, [filter.period, campaign?.createdAt])

    const responseQuery = query(collection(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CAMPAIGNS}/${campaignId}/${COLLECTIONS.RESPONSES}`),
        where('createdAt', '>', responseBreakDate.getTime()));
    const { status, data: responsesData } = useFirestoreCollectionData(responseQuery, { idField: 'id', });
    const text = useText();
    const responses = useMemo(() => {
        let results = responsesData as Response[];
        if (filter.tag) {
            results = results.filter(response => response.tags?.includes(filter.tag));
        }

        return results;
    }, [responsesData, filter]);
    const isLoadingResponses = status === 'loading' || !responses;

    if (!campaign) {
        return <Layout><Loading /></Layout>
    }

    const highlightIx = campaign.steps.findIndex(step => {
        const overallScore = step.conditions?.overallScore || [];
        return overallScore.length === 2 && overallScore.includes(0) && overallScore.includes(1);
    });
    const painpointIx = campaign.steps.findIndex(step => {
        const overallScore = step.conditions?.overallScore || [];
        return overallScore.length === 2 && overallScore.includes(3) && overallScore.includes(2);
    });
    const openQuestionix = campaign.steps.findIndex(step => step.type === 'openText');

    return (
        <div>
            <ResultsToolbar campaign={campaign} filter={filter} updateFilter={setFilter} />

            <div className='mx-4'>
                <h3 className='mt-5 mb-4'>{text.info__summary}</h3>

                <PeriodPicker filter={filter} updateFilter={setFilter} />

                <div className='row mt-5'>
                    <div className='col-9'>
                        <div className='row'>
                            <div className='col-4'>
                                {isLoadingResponses ? <Loading /> : <HappyIndexWrapper responses={responses} step={campaign.steps[0]} />}
                            </div>
                            <div className='col-8'>
                                {isLoadingResponses ? <Loading /> : <Summary responses={responses} step={campaign.steps[0]} />}
                            </div>
                        </div>

                        <div className='row mt-3'>
                            <div className='col-12'>
                                <Card>
                                    <p className='bold mt-2 ms-2'>{text.info__results}</p>
                                    {isLoadingResponses ? <Loading /> : <Timeline responses={responses} step={campaign.steps[0]} dateRange={[responseBreakDate, new Date()]} />}
                                </Card>
                            </div>
                        </div>


                        <div className='row mt-3'>
                            {!!campaign.steps[highlightIx] && <div className='col-6'>
                                <Card>

                                    <p className='bold mt-2 ms-2'>{text.info__highlights}</p>
                                    {isLoadingResponses ? <Loading /> : <QuestionVisualizations responses={responses} questionIx={highlightIx} step={campaign.steps[highlightIx]} />}
                                </Card>
                            </div>}
                            {!!campaign.steps[painpointIx] && <div className='col-6'>
                                <Card>
                                    <p className='bold mt-2 ms-2'>{text.info__pain_points}</p>
                                    {isLoadingResponses ? <Loading /> : <QuestionVisualizations responses={responses} questionIx={painpointIx} step={campaign.steps[painpointIx]} />}
                                </Card>
                            </div>}
                        </div>
                    </div>
                    {openQuestionix >= 0 && <div className='col-3 row-2'>
                        <Card>

                            <p className='bold mt-2 ms-2'>{text.info__open_feedback}</p>
                            <div className='px-3'>

                                {isLoadingResponses ? <Loading /> : <OpenQuestionPreview responses={responses} questionIx={openQuestionix} />}
                            </div>
                        </Card>
                    </div>}
                </div>



            </div>
        </div>
    )
}
