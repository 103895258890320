import React from 'react'
import { Link } from 'react-router-dom';
import { useText } from '../contexts/TextContext';

export default function Navigation() {
    const text = useText();
    const backPath = '/';

    if (window.location.pathname === backPath) {
        return <></>
    }

    return <div>
        <Link to={backPath}><i className="bi bi-chevron-double-left me-1" />{text.back}</Link>
    </div>
}
