import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import React, { useEffect, useMemo, useRef } from 'react'
import { Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useFirestore, useUser } from 'reactfire';
import { useText } from '../../contexts/TextContext';
import Company from '../../models/Company';
import { COLLECTIONS } from '../../utils/shared/constants';
import { useCustomClaims } from '../RequireAuth';

interface Props {
    show: boolean;
    onHide: () => void;
    companyToEdit?: Company;
}


type Inputs = {
    name: string,
};


export default function NewCompanyModal(props: Props) {
    const text = useText();
    const { superUser } = useCustomClaims();
    const { register, reset, handleSubmit, formState: { errors } } = useForm<Inputs>();

    const [processing, setProcessing] = React.useState(false);
    const firestore = useFirestore();
    const user = useUser();
    const [users, setUsers] = React.useState<string[]>([]);
    const [activeIx, setActiveIx] = React.useState(-1);
    const activeInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (props.show) {
            const defaultValues: Inputs = {
                name: props.companyToEdit?.name || '',
            };
            reset(defaultValues);
            let defaultUsers: string[] = [];
            if (!superUser) {
                defaultUsers = [user.data?.email || ''];
            }


            setUsers(props.companyToEdit?.users || defaultUsers)
            setActiveIx(-1);
        }
    }, [props.show, props.companyToEdit, reset, user.data, superUser])

    useEffect(() => {
        if (activeInput.current) {
            activeInput.current?.focus()
        }
        // eslint-disable-next-line
    }, [activeInput.current, activeIx])

    const userErrors = useMemo(() => {
        return users.map(user => !user.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/))
    }, [users])
    const anyError = !!errors.name || userErrors.some(error => error);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setProcessing(true);

        const company: Partial<Company> = {
            ...data,
            users,
        }

        if (props.companyToEdit) {
            await setDoc(doc(firestore, COLLECTIONS.COMPANIES, props.companyToEdit.id), company);
        } else {
            company.createdAt = new Date().getTime();
            company.createdBy = user.data?.uid || '';
            await addDoc(collection(firestore, COLLECTIONS.COMPANIES), company);
        }

        setProcessing(false);
        reset();
        props.onHide();
    }

    function updateUser(value: string, index: number): void {
        const newUsers = [...users];
        newUsers[index] = value.toLowerCase();
        setUsers(newUsers);
    }

    function removeUser(index: number, e: React.MouseEvent<HTMLElement>): void {
        e.stopPropagation();
        const newUsers = [...users];
        newUsers.splice(index, 1);
        setUsers(newUsers);
    }

    function addUser(e: React.MouseEvent<HTMLElement>): void {
        e.stopPropagation();
        if (activeIx < 0 || !userErrors[activeIx]) {
            setUsers([...users, '']);
            setActiveIx(users.length);
        } else {
            activeInput.current?.focus();
        }
    }


    function updateActive(index: number, e: React.MouseEvent<HTMLElement>): void {
        e.stopPropagation();
        if (activeIx < 0 || !userErrors[activeIx]) {
            setActiveIx(index);
        } else {
            activeInput.current?.focus();
        }
    }

    return (
        <Modal show={props.show} onHide={props.onHide} className="modal fade">
            <Modal.Header closeButton className='border-0' onClick={(e) => updateActive(-1, e)}>
                <Modal.Title>{props.companyToEdit ? text.company__edit_company : text.company__new_company}</Modal.Title>
            </Modal.Header>
            <Modal.Body onClick={(e: any) => updateActive(-1, e)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label className='mt-3'>{text.name}</label>
                    <input className={`form-control mt-1 ${errors.name ? 'is-invalid' : ''}`} type="text"
                        {...register("name", { required: true })} />

                    <label className='mt-3'>{text.companies__users}</label>
                    <div className={`d-flex rounded border flex-wrap`}>

                        {users.map((currentUser, index) => {
                            const disabled = !superUser && currentUser === user.data?.email;
                            const isActive = activeIx === index;
                            const firstName = currentUser.replace(/@.*/, '');
                            return (
                                <div key={index} className="d-flex align-items-center m-1 border">
                                    {isActive
                                        ? <input ref={activeInput} className={`form-control ${userErrors[index] ? 'is-invalid' : ''} border-0`}
                                            type="email"
                                            value={currentUser}
                                            onChange={(e) => updateUser(e.target.value, index)}
                                        />
                                        : <button type="button" disabled={disabled} onClick={(e) => updateActive(index, e)} className='btn'>
                                            {firstName}
                                        </button>}

                                    <button
                                        disabled={disabled}
                                        className='btn' type="button" onClick={(e) => removeUser(index, e)}>X</button>
                                </div>
                            )
                        })}

                        <button className='btn' type="button" onClick={(e) => addUser(e)}>{text.company__enter_new_user_email}</button>
                    </div>


                    <div className='mt-5 d-flex'>
                        <button className='btn btn-outline-secondary me-2 flex-1' type="button" onClick={() => props.onHide()}>{text.cancel}</button>
                        <button className='btn btn-primary flex-1' type="submit" disabled={processing || anyError}>{text.save}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}
