import React, { useMemo } from 'react'
import { useText } from '../../contexts/TextContext'
import CampaignStep from '../../models/CampaignStep';
import Response from '../../models/Response';
import { formatPercentage } from '../../utils/math';
import Card from './Card';
import HapinessIndex from './HapinessIndex';

interface Props {
    responses: Response[],
    step: CampaignStep
}

export default function HappyIndexWrapper(props: Props) {
    const { responses } = props;
    const text = useText();


    const aggregated = useMemo(() => {
        const scores: Record<number, number> = {
            0: 0,
            1: 0,
            2: 0,
            3: 0,
        }

        for (const response of responses) {
            scores[Number(response.steps[0].value)]++;
        }


        return {
            scores,
            total: responses.length,
        }
    }, [responses]);

    const possitiveCount = aggregated.scores[0] + aggregated.scores[1];
    const negativeCount = aggregated.scores[2] + aggregated.scores[3];
    const value = possitiveCount / (possitiveCount + negativeCount);
    return (
        <Card >
            <div className='d-flex'>

                <HapinessIndex value={value} />
                <div className='d-flex flex-column ms-2'>
                    <p className='mb-4 bold'>{text.summary__happy_index}</p>
                    <p className='mb-1'>{text.score}</p>
                    <div className='h3'>{formatPercentage(value, { noSign: true })}</div>
                </div>
            </div>
        </Card>
    )
}
