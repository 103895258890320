import { addDoc, collection } from 'firebase/firestore';
import React from 'react'
import { Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useFirestore } from 'reactfire';
import { useText } from '../../contexts/TextContext';
import Tag from '../../models/Tag';
import { COLLECTIONS } from '../../utils/shared/constants';
import { useCompanyId } from '../RequireCompany';

interface Props {
    show: boolean;
    onHide: () => void;
}


type Inputs = {
    name: string,
};


export default function NewLocationModal(props: Props) {
    const text = useText();
    const { register, reset, handleSubmit, formState: { errors } } = useForm<Inputs>();
    const [processing, setProcessing] = React.useState(false);
    const firestore = useFirestore();
    const companyId = useCompanyId();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setProcessing(true);

        const location: Partial<Tag> = {
            ...data,
            createdAt: new Date().getTime(),
            type: 'location',
        }

        await addDoc(collection(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.TAGS}`), location);
        setProcessing(false);
        reset();
        props.onHide();
    }

    return (
        <Modal show={props.show} onHide={props.onHide} className="modal fade">
            <Modal.Header closeButton>
                <Modal.Title>{text.locations__new_location}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label className='mt-3'>{text.name}</label>
                    <input className={`form-control mt-1 ${errors.name ? 'is-invalid' : ''}`} type="text" {...register("name", { required: true })} />

                    <div className='mt-5 d-flex'>
                        <button className='btn btn-outline-secondary me-2 flex-1' onClick={() => props.onHide()}>{text.cancel}</button>
                        <button className='btn btn-primary flex-1' disabled={processing}>{text.save}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}
