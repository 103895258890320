import React from 'react'
import { useText } from '../../contexts/TextContext'
import Response from '../../models/Response'
import Emoji from '../Emoji'

interface Props {
    responses: Response[],
    questionIx: number
}

const PREVIEW_COUNT = 3;

export default function OpenQuestionPreview(props: Props) {
    const text = useText();
    const [showMore, setShowMore] = React.useState(false);

    const { responses, questionIx } = props;
    const data = responses
        .filter(response => {
            if (response.steps.length <= questionIx) {
                return false;
            }
            if (response.steps[questionIx].skipped) {
                return false;
            }
            return true;
        })

    if (!data.length) {
        return <div className='center'>{text.info__no_responses}</div>
    }

    data.sort((a, b) => b.createdAt - a.createdAt);

    const visible = data.slice(0, showMore ? data.length : PREVIEW_COUNT);

    return (
        <div className=''>{visible.map(response => {
            const emojiIx = Number(response.steps[0].value);
            return <div key={response.id} className="d-flex my-2">
                <Emoji ix={emojiIx} size="small" />
                <div className='ms-3 flex-1'>
                    <small >{new Date(response.createdAt).toLocaleString()}</small>
                    <textarea className='form-control inline mt-2' value={response.steps[questionIx].value} readOnly />
                </div>
            </div>
        })}
            {data.length > PREVIEW_COUNT && <div className='center'>
                <button className='btn' onClick={() => setShowMore(!showMore)}>{showMore ? text.showLess : text.showMore}
                </button>
            </div>
            }
        </div>
    )
}
