import { useText } from '../../contexts/TextContext';
import CampaignStep from '../../models/CampaignStep';

interface Props {
    step: CampaignStep
    editable?: boolean;
    onChange?: (step: CampaignStep) => void;
    size?: 'small' | 'large';
}

export default function FinalScreen(props: Props) {
    const { step, editable } = props;
    const text = useText();
    const onChange = props.onChange || (() => { });
    const textSize = props.size === 'small' ? 'h6' : 'h1 bold';

    return (
        <div className={`container px-2 center flex-column flex-1 py-5 question`}>
            {editable
                ? <input className="form-control text-center" style={{ fontSize: '2.5rem' }}
                    placeholder={text.campaign__thank_you_note + '...'}
                    value={step.text} onChange={(e) => onChange({ ...step, text: e.target.value })} />
                : <h1 className={textSize}>{step.text}</h1>}
        </div>
    )
}
