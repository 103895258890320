import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, updateProfile } from 'firebase/auth';
import React from 'react'
import { useForm } from 'react-hook-form';
import { useAuth } from 'reactfire';
import { useText } from '../contexts/TextContext';
import SignInIcon from './SignInIcon';
import googleLogo from '../assets/google_logo.png';
import useQueryParam from '../hooks/useQueryParams';
import { PARAMS, PATHS } from '../utils/shared/constants';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

type Inputs = {
    email: string,
    password: string,
    name: string,
};

export default function SignIn() {
    const auth = useAuth();
    const text = useText();
    const { invite, debug, signUp } = useQueryParam();
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
    const [error, setError] = React.useState<JSX.Element | string>('');
    const isSignUp = !!invite || signUp;

    const handleGoogleAuth = () => {
        signInWithPopup(auth, new GoogleAuthProvider())
    }

    const onSubmit = async (data: Inputs): Promise<void> => {
        try {
            if (isSignUp) {
                const userCreds = await createUserWithEmailAndPassword(auth, invite || data.email, data.password)
                await updateProfile(userCreds.user, { displayName: data.name });
            } else {
                await signInWithEmailAndPassword(auth, data.email, data.password);
            }
        } catch (error: any) {
            const code: string = error.code || '';

            const expectedErrorCodes: Record<string, string> = {
                'auth/weak-password': text.sign_in__weak_password,
                'auth/wrong-password': text.sign_in__wrong_password,
                'auth/user-not-found': text.sign_in__wrong_password,
            }

            if (expectedErrorCodes[code]) {
                setError(expectedErrorCodes[code]);
            } else if (code === 'auth/email-already-in-use') {
                setError(<div>
                    {text.sign_in__email_already_in_use}
                    <a href={`/`} className='ms-1'>{text.sign_in}</a>
                </div>);
            } else {
                console.error('Failed on step', error);
            }
        }

    }

    return (
        <div className="center h-100">
            <div className='center flex-column' style={{ minWidth: '300px' }}>
                <SignInIcon />
                <h2>{isSignUp ? text.sign_in__sign_up_header : text.sign_in__header}</h2>
                <p className='mb-4'>{isSignUp ? text.sign_in__sign_up_description : text.sign_in__description}</p>


                {error && <div className='alert alert-warning'>{error}</div>}
                <form className='d-flex flex-column w-100' onSubmit={handleSubmit(onSubmit)}>
                    <label>{text.email}</label>
                    {invite
                        ? <p>{invite}</p>
                        : <input
                            className={`form-control mb-3 ${errors.email ? 'is-invalid' : ''}`}
                            type="email"  {...register("email", { required: true })}
                        />
                    }

                    {isSignUp && <>
                        <label>{text.name}</label>
                        <input className={`form-control  mb-3  ${errors.name ? 'is-invalid' : ''}`} type="text"  {...register("name", { required: true })} />
                    </>}

                    <label>{text.password}</label>
                    <input className={`form-control  mb-3  ${errors.password ? 'is-invalid' : ''}`} type="password"  {...register("password", { required: true, min: 6 })} />

                    {!isSignUp && <Link to={`/${PATHS.PWRD_RESET}`}>
                        <Button variant='link' size='sm' >Forgot password?</Button>
                    </Link>}
                    <button className='btn btn-primary mt-3' type="submit">{isSignUp ? text.save : text.sign_in}</button>
                    {debug && <button className='btn border mt-3 py-2' type="button" onClick={handleGoogleAuth}>
                        <img src={googleLogo} alt="Google" className='me-2' width="24" height="24" />
                        {isSignUp ? text.sign_in__sign_up_google : text.sign_in__google}
                    </button>}

                </form>

                <small className='mt-3 signin-switch-text'>
                    {isSignUp
                        ? <>
                            {text.sign_in__already_have_account}
                            <a href='/'>
                                <button className='btn text-primary p-1'>
                                    {text.sign_in}
                                </button>
                            </a>
                        </>
                        : <>
                            <span>{text.sign_in__already_have_account}</span>
                            <a href={`/?${PARAMS.SIGN_UP}=true`}>
                                <button className='btn text-primary p-1'>
                                    {text.sign_in__sign_up}
                                </button>
                            </a>
                        </>
                    }
                </small>
            </div>
        </div >
    )
}

