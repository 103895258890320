import React from 'react'
interface Props {
    ix: number;
    size?: 'small' | 'large';
}
export default function Emoji(props: Props) {
    const size = props.size || 'large';

    const EMOJIS = [
        <svg className={`smiley ${size}`} viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M105 0C47.25 0 0 46.9026 0 105C0 163.059 47.25 210 105 210C163.097 210 210 163.097 210 105C210 46.9026 163.097 0 105 0ZM105 201.7C51.6893 201.7 8.29963 158.619 8.29963 105C8.29963 51.9982 51.6893 8.26103 105 8.26103C158.619 8.26103 201.7 51.9982 201.7 105C201.7 158.619 158.619 201.7 105 201.7ZM55.8585 79.136C55.8585 70.2188 63.193 63.193 71.8015 63.193C80.7188 63.193 88.0919 70.2188 88.0919 79.136C88.0919 88.0533 80.7574 95.4265 71.8015 95.4265C63.193 95.4265 55.8585 88.0919 55.8585 79.136ZM125.112 79.136C125.112 70.2188 132.447 63.193 141.403 63.193C150.358 63.193 157.693 70.2188 157.693 79.136C157.693 88.0533 150.358 95.4265 141.403 95.4265C132.447 95.4265 125.112 88.0919 125.112 79.136ZM52.0368 123.529L162.789 123.838C155.763 147.772 133.759 165.336 107.239 165.336C81.0662 165.336 59.0625 147.772 52.0368 123.529Z"
                fill="#0DAA5D" />
        </svg>,
        <svg className={`smiley ${size}`} viewBox="0 0 211 210" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M105.345 0C47.3665 0 0 46.9026 0 105C0 163.097 47.4053 210 105.345 210C163.943 210 211 163.097 211 105C211.039 46.9412 163.943 0 105.345 0ZM105.345 201.43C52.169 201.43 8.63673 158.349 8.63673 105.039C8.63673 51.7279 52.2078 8.33824 105.345 8.33824C159.141 8.33824 202.673 51.7279 202.673 105.039C202.712 158.311 159.141 201.43 105.345 201.43ZM56.042 79.1746C56.042 70.2188 63.4007 62.8842 72.386 62.8842C81.3713 62.8842 88.73 70.2188 88.73 79.1746C88.73 88.0919 81.3713 95.1176 72.386 95.1176C63.4007 95.1176 56.042 88.0919 56.042 79.1746ZM125.833 79.1746C125.833 70.2188 132.882 62.8842 141.829 62.8842C150.775 62.8842 158.173 70.2188 158.173 79.1746C158.173 88.0919 150.814 95.1176 141.829 95.1176C132.882 95.1176 125.833 88.0919 125.833 79.1746ZM163.633 120.982C156.585 145.224 134.199 162.789 107.901 162.789C81.6424 162.789 59.2179 144.915 52.1691 120.982C64.3302 138.855 84.8183 150.05 107.901 150.05C130.984 150.358 151.472 138.855 163.633 120.982Z" fill="#99CD9B" />
        </svg>,
        <svg className={`smiley ${size}`} viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M105 0C46.9026 0 0 46.9026 0 105C0 163.097 46.9026 210 105 210C162.789 210 210 163.097 210 105C210 46.9026 162.789 0 105 0ZM105 201.7C51.6893 201.7 8.29967 158.619 8.29967 105C8.29967 51.6893 51.7279 8.29963 105 8.29963C158.311 8.29963 201.7 51.7279 201.7 105C201.7 158.619 158.311 201.7 105 201.7ZM71.8015 95.1177C62.8456 95.1177 55.511 87.7831 55.511 79.1746C55.511 70.2188 62.8456 62.8842 71.8015 62.8842C80.7188 62.8842 88.0533 70.2188 88.0533 79.1746C88.0919 87.7831 80.7574 95.1177 71.8015 95.1177ZM125.112 79.136C125.112 70.1801 132.447 62.8456 141.403 62.8456C150.32 62.8456 157.346 70.1801 157.346 79.136C157.346 87.7445 150.32 95.079 141.403 95.079C132.447 95.1176 125.112 87.7831 125.112 79.136ZM155.454 114.574L161.206 127.351L68.5974 168.502L62.8456 155.415L155.454 114.574Z" fill="#EF9EA1" />
        </svg>,
        <svg className={`smiley ${size}`} viewBox="0 0 210 210" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M104.845 0C46.9717 0 0 46.9026 0 105C0 163.097 46.9717 210 104.845 210C163.338 210 210 163.097 210 105C210 46.9026 163.338 0 104.845 0ZM104.845 201.7C51.4562 201.7 8.31189 158.619 8.31189 105C8.31189 52.0368 51.4562 8.29963 104.845 8.29963C158.544 8.29963 201.997 52.0368 201.997 105C202.036 158.619 158.544 201.7 104.845 201.7ZM55.6314 79.136C55.6314 70.1801 62.9768 63.193 71.5979 63.193C80.8763 63.193 87.9124 70.2187 87.9124 79.136C87.9124 88.0919 80.8763 95.079 71.5979 95.079C62.9768 95.1176 55.6314 88.0919 55.6314 79.136ZM125.296 79.136C125.296 70.1801 132.332 63.193 141.611 63.193C150.541 63.193 157.577 70.2187 157.577 79.136C157.577 88.0919 150.541 95.079 141.611 95.079C132.332 95.1176 125.296 88.0919 125.296 79.136ZM161.753 164.989C149.304 147.425 128.814 135.96 106.121 135.96C82.7706 135.96 62.6676 147.463 50.1804 165.298C57.5258 141.055 79.6005 123.491 106.121 123.491C132.332 123.182 154.407 140.746 161.753 164.989Z" fill="#E24E67" />
        </svg>
    ];
    return EMOJIS[props.ix]
}
