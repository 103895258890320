import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useFirestore } from 'reactfire';
import Loading from '../components/Loading';
import FinalScreen from '../components/steps/FinalScreen';
import OpenText from '../components/steps/OpenText';
import OverallScore from '../components/steps/OverallScore';
import Question from '../components/steps/Question';
import { useResetResponse } from '../contexts/ResetResponseContext';
import { useText } from '../contexts/TextContext';
import Campaign from '../models/Campaign';
import Response from '../models/Response';
import ResponseLink from '../models/ResponseLink';
import { COLLECTIONS } from '../utils/shared/constants';

export default function ResponsePage() {
    const { linkId } = useParams();
    const [response, setResponse] = React.useState<Response | null>(null);
    const { activate, setShort } = useResetResponse();
    const text = useText();
    const firestore = useFirestore();
    const [currentStepIx, setCurrentStepIx] = React.useState(0);
    const [campaign, setCampaign] = React.useState<Campaign | null>(null);
    const [link, setLink] = React.useState<ResponseLink | null>(null);
    const [processing, setProcessing] = React.useState(false);


    useEffect(() => {
        const fetchCampaign = async () => {
            const link = await getDoc(doc(firestore, `${COLLECTIONS.LINKS}/${linkId}`));
            const { company: companyId, campaign: campaignId } = link.data() as ResponseLink;
            const campaignRef = doc(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CAMPAIGNS}/${campaignId}`);
            const campaign = await getDoc(campaignRef);
            setCampaign(campaign.data() as Campaign);
            setLink(link.data() as ResponseLink);
        }
        fetchCampaign();
    }, [linkId, firestore])

    useEffect(() => {
        if (!campaign) {
            return;
        }
        if (currentStepIx >= campaign.steps.length || campaign.steps[currentStepIx].type === 'finalScreen') {
            setShort(true);
        }
        setProcessing(false);
    }, [currentStepIx, campaign, setShort]);

    useEffect(() => setProcessing(false), [currentStepIx]);

    if (!campaign || !link || processing) {
        return <div className='center flex-1'>
            <Loading />
        </div>
    }

    const shouldSkipStep = (newResponse: Response, stepIx: number) => {
        const conditions = campaign.steps[stepIx].conditions;
        if (!conditions) {
            return false;
        }

        const overallScoreCondition = conditions.overallScore;
        if (overallScoreCondition && overallScoreCondition.length > 0) {
            const overallScore = Number(newResponse.steps[0].value) || 0;
            return !overallScoreCondition.includes(overallScore);
        }

        return false;
    }
    const advanceStep = (newResponse: Response) => {
        let nextStepIx = currentStepIx + 1;
        while (nextStepIx < campaign.steps.length && shouldSkipStep(newResponse, nextStepIx)) {
            newResponse.steps!.push({
                value: '',
                skipped: true,
                type: campaign.steps[nextStepIx].type,
            })
            nextStepIx++;
        }
        setResponse(newResponse);
        setCurrentStepIx(nextStepIx);
    }

    const submitStep = async (value: number | string) => {
        setProcessing(true);
        const newResponseStep = {
            value,
            type: campaign.steps[currentStepIx].type,
        }
        let newResponse: Response | null = null;
        if (response) {
            const responseRef = doc(firestore,
                `${COLLECTIONS.COMPANIES}/${link.company}/${COLLECTIONS.CAMPAIGNS}/${link.campaign}/${COLLECTIONS.RESPONSES}/${response.id}`);
            newResponse = {
                ...response,
                steps: [...response.steps, newResponseStep],
            }
            await setDoc(responseRef, newResponse);
        } else {
            const responseToBeCreated: Partial<Response> = {
                createdAt: new Date().getTime(),
                steps: [
                    newResponseStep
                ],
                tags: link.tags,
            }

            const response = await addDoc(collection(firestore,
                `${COLLECTIONS.COMPANIES}/${link.company}/${COLLECTIONS.CAMPAIGNS}/${link.campaign}/${COLLECTIONS.RESPONSES}`),
                responseToBeCreated);

            newResponse = { ...responseToBeCreated, id: response.id } as Response;

            activate(() => { window.location.reload(); });
        }
        advanceStep(newResponse);
    }

    const skipStep = () => {
        if (response) {
            const newResponseStep = {
                value: '',
                skipped: true,
                type: campaign.steps[currentStepIx].type,
            }
            const newResponse: Response = {
                ...response,
                steps: [...response.steps, newResponseStep],
            }
            advanceStep(newResponse);
        }
    }


    const renderContent = () => {
        if (!campaign) {
            return <p className='fs-3'>{text.response__no_campaign_found}</p>
        }

        if (currentStepIx >= campaign.steps.length) {
            return <p className='fs-3'>{text.response__submitted}</p>
        }
        const step = campaign.steps[currentStepIx];
        switch (step.type) {
            case 'overallScore':
                return <OverallScore step={step} onClick={submitStep} />
            case 'question':
                return <Question step={step} onClick={submitStep} />
            case 'openText':
                return <OpenText step={step} onSubmit={submitStep} onSkip={skipStep} />
            case 'finalScreen':
                return <FinalScreen step={step} />
            default:
                return <Loading />
        }
    }

    return (
        <div className='flex-1 center flex-column'>
            {renderContent()}
        </div>
    )
}
