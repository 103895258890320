export enum COLLECTIONS {
    COMPANIES = 'companies',
    CAMPAIGNS = 'campaigns',
    RESPONSES = 'responses',
    TAGS = 'tags',
    LINKS = 'links',
}

export enum PATHS {
    NEW_CAMPAIGN = 'new-campaign',
    CAMPAIGN = 'campaign',
    BUILDER = 'builder',
    RESPOND = 'respond',
    LOCATIONS = 'locations',
    LINKS = 'links',
    COMPANIES = 'companies',
    PWRD_RESET = 'auth/reset',

}

export enum PARAMS {
    NEW = 'new',
    INVITE = 'invite',
    DEBUG = 'debug',
    SIGN_UP = 'signUp',
}

export const EMOJI_COLORS = ['#0daa5d', '#99cd9b', '#ef9ea1', '#e24e67'];
