import React from 'react'
import CampaignStep from '../../models/CampaignStep'
import FinalScreen from '../steps/FinalScreen'
import OpenText from '../steps/OpenText'
import OverallScore from '../steps/OverallScore'
import Question from '../steps/Question'

interface Props {
    step: CampaignStep
}

export default function StepIcon(props: Props) {
    const renderStep = () => {
        switch (props.step.type) {
            case 'overallScore':
                return <OverallScore step={props.step} size="small" />;
            case 'question':
                return <Question step={props.step} size="small" />;
            case 'openText':
                return <OpenText step={props.step} size="small" />;
            case 'finalScreen':
                return <FinalScreen step={props.step} size="small" />;
            default:
                return <p>TODO</p>
        }
    }

    return (
        <div className='step-icon center flex-column rounded'>
            {renderStep()}
        </div>
    )
}
