import React from 'react'
import Header from './Header'

interface Props {
    children: any
}

export default function Layout(props: Props) {
    return (
        <div>
            <Header className='mb-5' />
            <div className='container'>
                {props.children}
            </div>
        </div>
    )
}
