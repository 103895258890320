import { useText } from '../../contexts/TextContext';
import CampaignStep from '../../models/CampaignStep';
interface Props {
    step: CampaignStep
    onClick?: (id: string) => void;
    disabled?: boolean;
    editable?: boolean;
    onChange?: (step: CampaignStep) => void;
    size?: 'small' | 'large';
}
export default function Question(props: Props) {
    const { step, disabled, editable } = props;
    const text = useText();
    const onChange = props.onChange || (() => { });
    const answerClass = props.size === 'small' ? 'py-0 small' : '';
    const containerClass = props.size === 'small' ? 'small' : '';


    const updateOption = (index: number, newText: string) => {
        const newOptions = step.options.map((step, i) => {
            if (i === index) {
                return { ...step, text: newText };
            }
            return step;
        });
        const newStep: CampaignStep = { ...step, options: newOptions };
        onChange(newStep);
    }

    return (
        <div className={`container px-2 center flex-column flex-1 ${containerClass} question`}>
            {editable
                ? <input className="form-control header" style={{ fontSize: '2.5rem' }}
                    placeholder={text.campaign__question + '...'}
                    value={step.text} onChange={(e) => onChange({ ...step, text: e.target.value })} />
                : <h1 className="bold">{step.text}</h1>}
            <div className='mx-3 w-100'>
                {step.options.map((option, index) => {
                    if (editable) {
                        return <div className="input-group" key={option.id}>
                            <input className="form-control answer text-center"
                                placeholder={text.campaign__answer + '...'}
                                value={option.text}
                                onChange={(e) => updateOption(index, e.target.value)}
                            />
                        </div>
                    }
                    return <button key={option.id} className={`btn-answer center w-100 ${answerClass} answer`} disabled={disabled}
                        onClick={() => { props.onClick && props.onClick(option.id) }}>
                        {option.text}
                    </button>
                })}
            </div>
        </div>
    )
}
