import React, { useEffect, useRef } from 'react'
import CampaignStep from '../../models/CampaignStep'
import Response from '../../models/Response'
import * as Plot from "@observablehq/plot";
import { EMOJI_COLORS } from '../../utils/shared/constants';

interface Props {
    responses: Response[],
    questionIx: number
    step: CampaignStep
}

export default function QuestionVisualizations(props: Props) {
    const { responses, questionIx, step } = props;
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const optionIds = step.options.map(option => option.id);
        const optionLabels = step.options.map(option => option.text);
        const domain = [];
        for (const id of optionIds) {
            domain.push(id + "_label", id);
        }

        const data = responses
            .filter(response => {
                if (response.steps.length <= questionIx) {
                    return false;
                }
                if (response.steps[questionIx].skipped) {
                    return false;
                }
                return true;
            })
            .map(response => {
                return {
                    value: response.steps[questionIx].value,
                    overallScore: Number(response.steps[0].value),
                    counter: 1
                }
            });


        if (divRef.current) {
            const chart = Plot.plot({
                style: {
                    background: "transparent",
                    width: '100%',
                },
                x: {
                    interval: 1,
                    label: '',
                    tickFormat: (num: number) => Math.round(num),
                },
                y: {
                    label: '',
                    domain,
                    tickFormat: null,
                },
                marks: [
                    Plot.frame(),
                    Plot.barX(data, {
                        x: 'counter',
                        y: 'value',
                        fill: (datum: any) => EMOJI_COLORS[datum.overallScore],
                    }),
                    Plot.text(optionLabels, {
                        y: optionIds.map(id => id + "_label"),
                        frameAnchor: "left",
                        textAnchor: 'start',
                    })

                ]
            });
            divRef.current.append(chart);
            return () => chart.remove();
        }

    }, [responses, questionIx, step]);

    return (
        <div className='mb-2' ref={divRef} />
    )
}
