import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import React from 'react'
import { Modal } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useFirestore } from 'reactfire';
import { useText } from '../../contexts/TextContext';
import Campaign from '../../models/Campaign';
import CampaignStep from '../../models/CampaignStep';
import ResponseLink from '../../models/ResponseLink';
import { COLLECTIONS, PARAMS, PATHS } from '../../utils/shared/constants';
import { shortId } from '../../utils/math';
import { useCompanyId } from '../RequireCompany';


interface Props {
    show: boolean;
    onHide: () => void;
}


type Inputs = {
    name: string,
    template: 'base' | 'blank',
};


export default function NewCampaignModal(props: Props) {
    const text = useText();
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
    const [processing, setProcessing] = React.useState(false);
    const firestore = useFirestore();
    const companyId = useCompanyId();
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        setProcessing(true);

        const steps: CampaignStep[] = [
            {
                type: 'overallScore',
                text: '',
                options: [],
            }
        ];


        if (data.template === 'base') {
            steps[0].text = text.template__base_overall_score;

            steps.push(
                {
                    type: 'question',
                    text: text.template__base_negative_question,
                    options: [
                        {
                            text: text.template__base_negative_answer_labels,
                            id: 'labels',
                        },
                        {
                            text: text.template__base_negative_answer_staff,
                            id: 'staff',
                        },
                        {
                            text: text.template__base_negative_answer_slow,
                            id: 'slow',
                        },
                        {
                            text: text.template__base_negative_answer_price,
                            id: 'price',
                        },
                    ],
                    conditions: {
                        overallScore: [2, 3],
                    }
                },
                {
                    type: 'question',
                    text: text.template__base_positive_question,
                    options: [
                        {
                            text: text.template__base_positive_answer_labels,
                            id: 'labels',
                        },
                        {
                            text: text.template__base_positive_answer_staff,
                            id: 'staff',
                        },
                        {
                            text: text.template__base_positive_answer_fast,
                            id: 'fast',
                        },
                        {
                            text: text.template__base_positive_answer_price,
                            id: 'price',
                        },
                    ],
                    conditions: {
                        overallScore: [0, 1],
                    }
                },
                {
                    type: 'openText',
                    text: text.template__base_open_question,
                    options: [],
                },
                {
                    type: 'finalScreen',
                    text: text.response__submitted,
                    options: [],
                }
            )
        }

        const campaign: Partial<Campaign> = {
            ...data,
            createdAt: new Date().getTime(),
            steps,
        }

        const newCampaign = await addDoc(collection(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CAMPAIGNS}`), campaign);
        const link: Partial<ResponseLink> = {
            campaign: newCampaign.id,
            company: companyId,
            tags: [],
        }
        await setDoc(doc(firestore, `${COLLECTIONS.LINKS}/${shortId()}`), link);
        navigate(`/${PATHS.BUILDER}/${newCampaign.id}?${PARAMS.NEW}=true`);
    }

    return (
        <Modal show={props.show} onHide={props.onHide} className="modal fade">
            <Modal.Header closeButton>
                <Modal.Title>{text.campaign__new_campaign}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label className='mt-3'>{text.name}</label>
                    <input className={`form-control mt-1 ${errors.name ? 'is-invalid' : ''}`} type="text" {...register("name", { required: true })} />

                    <label className='mt-3 mb-1'>{text.campaign__template}</label>
                    <select className="form-select" {...register('template')}>
                        <option value="base">{text.campaign__template_base}</option>
                        <option value="blank">{text.campaign__template_blank}</option>
                    </select>
                    <div className='mt-5 d-flex'>
                        <button className='btn btn-outline-secondary me-2 flex-1' onClick={() => props.onHide()}>{text.cancel}</button>
                        <button className='btn btn-primary flex-1' disabled={processing}>{text.save}</button>
                    </div>
                </form>
            </Modal.Body>

        </Modal>
    )
}
