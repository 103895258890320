import React from 'react'
import { useText } from '../../contexts/TextContext';
import CampaignStep from '../../models/CampaignStep';
import Emoji from '../Emoji';

interface Props {
    step: CampaignStep,
    onClick?: (value: number) => void;
    onChange?: (step: CampaignStep) => void;
    editable?: boolean,
    disabled?: boolean;
    size?: 'small' | 'large';
}

export default function OverallScore(props: Props) {
    const { editable, step } = props;
    const text = useText();
    const onChange = props.onChange || (() => { });
    const questionSize = props.size === 'small' ? 'h6' : 'h1 mb-5 bold';
    return (
        <div className='center flex-column question w-100'>
            {editable
                ? <input className="form-control text-center mb-5" style={{ fontSize: '2.5rem' }}
                    placeholder={text.campaign__question + '...'}
                    value={step.text} onChange={(e) => onChange({ ...step, text: e.target.value })} />
                : <h1 className={questionSize}>{step.text}</h1>}
            <div className={`center flex-wrap w-100 justify-content-around`}>
                {[0, 1, 2, 3].map((ix) => {
                    return <div key={ix} className={`m-2 ${props.disabled ? '' : 'pointer'}`} onClick={() => {
                        if (props.onClick) {
                            props.onClick(ix)
                        }
                    }}>
                        <Emoji size={props.size} ix={ix} />
                    </div>
                })}
            </div>
        </div>
    )
}
