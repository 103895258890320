import { customAlphabet } from 'nanoid'

const nanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 10)

type FormatPercentageOptions = {
    noSign?: boolean;
}

export const formatPercentage = (value: number, options: FormatPercentageOptions = {}) => {
    const percentage = Math.round((value || 0) * 10000) / 100;
    if (options.noSign) {
        return percentage + '';
    }

    return `${percentage}%`;
}

export const shortId = nanoid