import React from "react";

const SlimToggle = React.forwardRef(({ onClick, children, variant }: any, ref: any) => {
    let classes = 'btn';
    if (variant) {
        classes += ` btn-${variant}`;
    }

    return <button onClick={onClick} ref={ref} className={classes}>{children} <i className="bi bi-chevron-down bold" /></button>
});

export default SlimToggle;