import React from 'react'
import { Modal } from 'react-bootstrap';
import { QRCodeCanvas } from 'qrcode.react';
import { useText } from '../../contexts/TextContext';

interface Props {
    show: boolean;
    value: string;
    onHide: () => void;
}
const SIZE = 250;
const ELEMENT_ID = 'qr-code';
const FILENAME = 'QR_Code'

export default function QRCodeModal(props: Props) {
    const text = useText();

    const handleDownload = () => {
        const qrCodeEl = document.getElementById(ELEMENT_ID);
        if (qrCodeEl) {
            const svgUrl = (qrCodeEl as any)
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");

            console.log(svgUrl)
            let aEl = document.createElement("a");
            aEl.href = svgUrl;
            aEl.download = FILENAME + ".png";
            document.body.appendChild(aEl);
            aEl.click();
            document.body.removeChild(aEl);
        }
    }

    return (
        <Modal show={props.show} onHide={props.onHide} className="modal fade">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Modal.Title>{text.links__qrCode}</Modal.Title>
                <p>{text.links__qrCodeDescription}</p>

                <div className='center flex-column'>
                    <QRCodeCanvas value={props.value}
                        size={SIZE}
                        bgColor='transparent'
                        includeMargin={true}
                        id={ELEMENT_ID} />
                </div>

                <div className='mt-5 d-flex'>
                    <button className='btn btn-outline-secondary me-2 flex-1' onClick={() => props.onHide()}>{text.cancel}</button>

                    <button className='btn btn-primary flex-1'
                        style={{ width: SIZE }}
                        onClick={handleDownload}>
                        <i className="bi bi-download me-2" />
                        {text.download}
                    </button>
                </div>
            </Modal.Body>

        </Modal>
    )
}
