import { useEffect, useRef } from 'react'
import CampaignStep from '../../models/CampaignStep';
import Response from '../../models/Response';
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { EMOJI_COLORS } from '../../utils/shared/constants';

interface Props {
    responses: Response[],
    step: CampaignStep
    dateRange: [Date, Date]
}

export default function Timeline(props: Props) {
    const { responses } = props;
    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const periodRange = props.dateRange[1].getTime() - props.dateRange[0].getTime();
        const periodRangeHours = Math.floor(periodRange / (1000 * 60 * 60))
        const useHours = periodRangeHours < 48;

        const data = responses.map(response => {
            return {
                createdAt: new Date(response.createdAt),
                overallScore: Number(response.steps[0].value),
                counter: 1
            }
        });

        const showRange = props.dateRange.map(d => new Date(d));
        if (useHours) {
            showRange[0].setHours(showRange[0].getHours() - 1)
        } else {
            showRange[0].setDate(showRange[0].getDate() - 1)
        }


        if (divRef.current) {
            const chart = Plot.plot({
                height: 100,
                width: 800,
                style: {
                    background: "transparent",
                    width: '100%'
                },
                x: {
                    domain: showRange,
                    tickFormat: (d: Date) => {
                        const date = d.getDate() + '/' + (d.getMonth() + 1);
                        if (!useHours) {
                            return date;
                        }
                        return date + ' ' + d.getHours() + ':00';
                    },
                    type: 'utc',
                    label: '',
                    transform: (d: Date) => {
                        if (useHours) {
                            return d.setMinutes(-30);
                        }
                        return d.setHours(-12);
                        // return d;
                    }
                },
                y: {
                    grid: true,
                    label: '',
                },
                marks: [
                    Plot.rectY(data, {
                        x: 'createdAt',
                        y: 'counter',
                        fill: (datum: any) => EMOJI_COLORS[datum.overallScore],
                        interval: useHours ? d3.utcHour : d3.utcDay,
                        sort: (a: any, b: any) => b.overallScore - a.overallScore,
                    }),
                ]
            });
            divRef.current.append(chart);
            return () => chart.remove();
        }

    }, [responses, props.dateRange]);

    return (
        <div ref={divRef} />
    )
}
