import { collection, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect } from 'react'
import { Dropdown } from 'react-bootstrap';
import { useFirestore } from 'reactfire';
import Layout from '../components/Layout'
import NewCompanyModal from '../components/modals/NewCompanyModal';
import ThreeDotsToggle from '../components/ThreeDotsToggle';
import { useText } from '../contexts/TextContext';
import useConfirmModal from '../hooks/useConfirmModal';
import Company from '../models/Company';
import { COLLECTIONS } from '../utils/shared/constants';

export default function CompaniesPage() {
    const text = useText();
    const [showNewCompany, setShowNewCompany] = React.useState(false);
    const [editCompanyId, setEditCompanyId] = React.useState('');

    const [ConfirmModal, confirm] = useConfirmModal(text.companies__are_you_sure);
    const firestore = useFirestore();
    const [companies, setCompanies] = React.useState<Company[]>([]);


    useEffect(() => {
        return onSnapshot(collection(firestore, COLLECTIONS.COMPANIES), (data) => {
            const newCompanies = data.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                } as Company
            });
            newCompanies.sort((a, b) => a.name.localeCompare(b.name));
            setCompanies(newCompanies);
        }, (error) => { console.log('error loading companies', error) });
    }, [firestore])

    const handleDelete = (id: string) => {
        confirm(async () => {
            await deleteDoc(doc(firestore, `${COLLECTIONS.COMPANIES}/${id}`));
        })
    }

    const handleCloseModal = () => {
        setEditCompanyId('');
        setShowNewCompany(false);
    }

    const companyToEdit = companies.find(c => c.id === editCompanyId);
    return (
        <Layout>
            <div className='d-flex align-items-start justify-content-between'>
                <div>
                    <h1>{text.companies__companies}</h1>
                </div>
                <button className='btn btn-primary no-wrap ms-2 mt-3' onClick={() => setShowNewCompany(true)}>+ {text.company__new_company}</button>
            </div>

            <NewCompanyModal show={showNewCompany || !!companyToEdit} companyToEdit={companyToEdit} onHide={() => handleCloseModal()} />
            <ConfirmModal />

            {companies.map(company => <div key={company.id} className='d-flex rounded border m-2 p-2'>
                <i className="bi bi-diagram-3-fill text-primary me-2" />
                <span className="badge bg-primary center me-2">{(company.users || []).length}</span>
                <div className='me-auto'>{company.name}</div>
                <Dropdown>
                    <Dropdown.Toggle as={ThreeDotsToggle} />
                    <Dropdown.Menu align="end">
                        <Dropdown.Item className="" onClick={() => setEditCompanyId(company.id)}>{text.edit}</Dropdown.Item>
                        <Dropdown.Item className="text-light bg-danger" onClick={() => handleDelete(company.id)}>{text.remove}</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

            </div>)}

        </Layout>
    )
}
