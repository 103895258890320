import { collection, deleteDoc, doc, onSnapshot, orderBy, Query, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import Layout from '../components/Layout'
import NewCampaignModal from '../components/modals/NewCampaignModal';
import { useCompanyId } from '../components/RequireCompany';
import ThreeDotsToggle from '../components/ThreeDotsToggle';
import { useText } from '../contexts/TextContext'
import useConfirmModal from '../hooks/useConfirmModal';
import Campaign from '../models/Campaign';
import { COLLECTIONS, PATHS } from '../utils/shared/constants';

export default function DashboardPage() {
    const text = useText();
    const firestore = useFirestore();
    const companyId = useCompanyId();
    const [campaings, setCampaings] = useState<Campaign[]>([]);
    const [showNewCampaign, setShowNewCampaign] = useState(false);
    const [ConfirmModal, confirm] = useConfirmModal(text.campaing__are_you_sure);


    useEffect(() => {
        const campaignsQuery = query(collection(firestore,
            `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CAMPAIGNS}`), orderBy('createdAt', 'desc')) as Query<Campaign>;
        return onSnapshot(campaignsQuery, (data) => {
            const campaigns = data.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                } as Campaign
            });
            setCampaings(campaigns);
        }, (error) => { console.log('error loading campaigns', error) });
    }, [companyId, firestore])

    const removeCampaing = (id: string) => {
        confirm(async () => {
            const campaingRef = doc(firestore, `${COLLECTIONS.COMPANIES}/${companyId}/${COLLECTIONS.CAMPAIGNS}/${id}`);
            await deleteDoc(campaingRef);
        })
    }

    return (
        <Layout>
            <div className='d-flex align-items-start justify-content-between mb-4'>
                <div>
                    <h1>{text.campaign__campaigns}</h1>
                    <p>{text.campaign__description}</p>
                </div>
                <button className='btn btn-primary no-wrap ms-2 mt-3' onClick={() => setShowNewCampaign(true)}>+ {text.campaign__new_campaign}</button>
            </div>

            <NewCampaignModal show={showNewCampaign} onHide={() => setShowNewCampaign(false)} />
            <ConfirmModal />

            <div className='bg-light p-3'>
                {text.name}
            </div>

            {(campaings || []).map(campaign => <div key={campaign.id} className='mt-2'>
                <div className='border-bottom px-4 py-3 d-flex align-items-center justify-content-between'>
                    <div className='flex-1 pe-5 center justify-content-start'>
                        <div className='center me-2 bg-primary-light p-2 rounded-circle center' style={{ width: '45px' }}>
                            <i className="bi bi-file-earmark text-primary h4 m-0" />
                        </div>
                        <h5 className='m-0'>
                            {campaign.name}
                        </h5>
                    </div>
                    <div className='center'>
                        <Link className='mx-1' to={`${PATHS.CAMPAIGN}/${campaign.id}`}>
                            <button className='btn btn-outline-secondary me-3'>
                                <i className="bi bi-bar-chart-fill me-2"></i>
                                {text.campaing__view_results}
                            </button>
                        </Link>
                        <Dropdown>
                            <Dropdown.Toggle as={ThreeDotsToggle} />
                            <Dropdown.Menu align="end">
                                <Dropdown.Item as={Link} to={`${PATHS.BUILDER}/${campaign.id}`}>{text.edit}</Dropdown.Item>
                                <Dropdown.Item as={Link} to={`${PATHS.LINKS}/${campaign.id}`}>{text.links__links}</Dropdown.Item>
                                <Dropdown.Item className="text-light bg-danger" onClick={() => removeCampaing(campaign.id)}>{text.remove}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>)}
        </Layout>
    )
}
