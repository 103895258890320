import React from 'react'

export default function SignInIcon() {
    return (
        <div className='signin-icon-wrapper'>
            <div className='signin-icon-dot' />
            <div className='signin-icon-blur' />
        </div>
    )
}
