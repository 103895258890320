import { useForm } from 'react-hook-form';
import { useText } from '../../contexts/TextContext';
import CampaignStep from '../../models/CampaignStep';

interface Props {
    step: CampaignStep
    onSubmit?: (answer: string) => void;
    onSkip?: () => void;
    disabled?: boolean;
    editable?: boolean;
    onChange?: (step: CampaignStep) => void;
    size?: 'small' | 'large';
}

type Inputs = {
    answer: string,
};

export default function OpenText(props: Props) {
    const { step, disabled, editable } = props;
    const text = useText();
    const onChange = props.onChange || (() => { });
    const questionSize = props.size === 'small' ? 'h6' : 'h1 bold';
    const containerClass = props.size === 'small' ? 'small' : '';
    const { register, handleSubmit, watch } = useForm<Inputs>();

    const onSubmit = (data: Inputs) => {
        if (props.onSubmit) {
            props.onSubmit(data.answer);
        }
    }
    const onSkip = () => {
        if (props.onSkip) {
            props.onSkip();
        }
    }

    const answer = watch('answer');

    return (
        <div className={`container px-2 center flex-column flex-1 ${containerClass} question`}>
            {editable
                ? <input className="form-control header" style={{ fontSize: '2.5rem' }}
                    placeholder={text.campaign__question + '...'}
                    value={step.text} onChange={(e) => onChange({ ...step, text: e.target.value })} />
                : <h1 className={questionSize}>{step.text}</h1>}
            <form className='d-flex flex-column w-100' onSubmit={handleSubmit(onSubmit)}>
                <textarea className="form-control" disabled={disabled}
                    rows={5}
                    {...register('answer')}
                />
                <div className='center flex-column'>
                    <button className='btn btn-secondary btn-lg radius-full' disabled={disabled || !(answer || '').trim()}>{text.next}</button>
                    <button className='btn btn-lg mt-2 radius-full' type='button' disabled={disabled} onClick={onSkip}>{text.skip}</button>
                </div>
            </form>
        </div>
    )
}
