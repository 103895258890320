import { collection, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect } from 'react'
import { useFirestore, useUser } from 'reactfire'
import { COLLECTIONS } from '../utils/shared/constants';
import Loading from './Loading';
import Company from '../models/Company';
import Layout from './Layout';
import { useCustomClaims } from './RequireAuth';
import { AdvancedButton } from './AdvancedButton';
import NewCompanyModal from './modals/NewCompanyModal';

interface Props {
    children: any
    tolerateNoCompany?: boolean;
}

interface CompanyContext {
    currentCompany: Company | null;
    companies: Company[];
    selectCompany: (companyId: string) => void;
}

const companyContext = React.createContext<CompanyContext>({ currentCompany: null, companies: [], selectCompany: () => { } });

export const useCompany = () => React.useContext(companyContext);
export const useCompanyId = () => {
    const { currentCompany } = React.useContext(companyContext)
    return currentCompany?.id || '';
};

export default function RequireCompany(props: Props) {
    const { data: user } = useUser();
    const firestore = useFirestore();
    const [companies, setCompanies] = React.useState<Company[]>([]);
    const [loading, setLoading] = React.useState(true);
    const { superUser } = useCustomClaims();
    const [selectedCompany, setSelectedCompany] = React.useState('');
    const [showNewCompany, setShowNewCompany] = React.useState(false);

    useEffect(() => {
        const whereClauses = [];
        if (!superUser) {
            whereClauses.push(where('users', 'array-contains', user?.email));
        }
        const companyQuery = query(collection(firestore, COLLECTIONS.COMPANIES), ...whereClauses);
        return onSnapshot(companyQuery, (data) => {
            const companies = data.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                } as Company
            });
            setCompanies(companies);
            setLoading(false);
        }, (error) => { console.log('error loading companies', error) });
    }, [firestore, user, superUser])

    if (loading) {
        return <Loading />
    }

    if (!props.tolerateNoCompany && companies.length === 0) {
        return <Layout>
            <div className='center flex-column'>
                <h5>Add Company</h5>
                <p>Start by adding company</p>
                <AdvancedButton onClick={() => setShowNewCompany(true)} icon='bi-plus'>Add Company</AdvancedButton>
            </div>

            <NewCompanyModal show={showNewCompany} onHide={() => setShowNewCompany(false)} />
        </Layout>
    }

    const currentCompany = companies.find((company) => company.id === selectedCompany) || companies[0];
    const value: CompanyContext = {
        currentCompany,
        companies,
        selectCompany: setSelectedCompany,
    }
    return <companyContext.Provider value={value}>
        {props.children}
    </companyContext.Provider>
}
