import React, { useContext, useEffect, useState } from 'react'
import { useSigninCheck } from 'reactfire'
import Loading from './Loading';
import SignIn from './SignIn';

interface Props {
    children: any
}


interface CustomClaimsContext {
    superUser: boolean
};
const customClaimsContext = React.createContext<CustomClaimsContext>({ superUser: false });

export const useCustomClaims = () => useContext(customClaimsContext);


export default function RequireAuth(props: Props) {
    const { status, data: signInCheckResult } = useSigninCheck();
    const [customClaims, setCustomClaims] = useState<CustomClaimsContext>({ superUser: false })

    useEffect(() => {
        if (signInCheckResult?.signedIn && signInCheckResult?.user) {
            signInCheckResult.user.getIdTokenResult().then(
                (result) => setCustomClaims(result.claims as any));
        }
    }, [signInCheckResult])

    if (status === 'loading') {
        return <Loading />
    }

    if (!signInCheckResult.signedIn) {
        return <SignIn />
    }

    return <customClaimsContext.Provider value={customClaims}>
        {props.children}
    </customClaimsContext.Provider>
}
