import { signOut } from 'firebase/auth';
import React from 'react'
import { Dropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from 'reactfire';
import { useText } from '../contexts/TextContext'
import { PATHS } from '../utils/shared/constants';
import NewCompanyModal from './modals/NewCompanyModal';
import { useCustomClaims } from './RequireAuth';
import { useCompany } from './RequireCompany';
import ThreeDotsToggle from './ThreeDotsToggle';

interface Props {
    className?: string;
}

export default function Header(props: Props) {
    const text = useText();
    const auth = useAuth();
    const { currentCompany, companies, selectCompany } = useCompany();
    const { superUser } = useCustomClaims();
    const [showEditCompany, setShowEditCompany] = React.useState(false);

    const handleSignOut = () => {
        signOut(auth);
    }

    const renderCompany = () => {
        if (!currentCompany) {
            return <></>
        }

        if (companies.length === 1) {
            return <div className="nav-link disabled">{currentCompany.name}</div>
        }

        return <Dropdown>
            <Dropdown.Toggle variant="">
                {currentCompany.name}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {companies.map((company) => {
                    return <Dropdown.Item key={company.id} className='' onClick={() => selectCompany(company.id)}>{company.name}</Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>
    }

    return (
        <nav className={`${props.className || ''} `}>
            <div className='border-bottom'>
                <div className='container navbar justify-content-between'>
                    <div className='navbar'>

                        <Link className="navbar-brand bold text-primary" to={'/'}>{text.navbar__brand}</Link>
                        {renderCompany()}
                    </div>
                    <Dropdown>
                        <Dropdown.Toggle as={ThreeDotsToggle} />
                        <Dropdown.Menu align="end">
                            {currentCompany && <Dropdown.Item onClick={() => setShowEditCompany(true)}>{text.company__edit_company}</Dropdown.Item>}
                            <Dropdown.Item className="" onClick={() => handleSignOut()}>{text.navbar__sign_out}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <div className='border-bottom'>
                <div className='container navbar justify-content-start py-3'>
                    <NavLink className="me-4 ms-2 nav-link" to={'/'}>{text.campaign__campaigns}</NavLink>
                    <NavLink className="me-4 nav-link" to={`/${PATHS.LOCATIONS}`}>{text.locations__locations}</NavLink>
                    {superUser && <NavLink className="me-4 nav-link text-danger" to={`/${PATHS.COMPANIES}`}>{text.companies__companies}</NavLink>}
                </div>
            </div>

            {currentCompany && <NewCompanyModal show={showEditCompany} onHide={() => setShowEditCompany(false)} companyToEdit={currentCompany} />}
        </nav>
    )
}
