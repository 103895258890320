import { createContext, useContext } from "react";
import text from "./texts/base";

export const textContext = createContext(text);

export const useText = () => useContext(textContext);

interface Props {
    children: any;
}

export const TextProvider = ({ children }: Props) => {
    return (
        <textContext.Provider value={text}>
            {children}
        </textContext.Provider>
    );
};